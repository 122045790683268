

import React, { useState } from 'react';
import { Table } from "../../form_inputs/Table";
import moment from "moment";
import { IoCopy, IoCopyOutline, IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { render } from '@testing-library/react';



const TransactionsTable = ({rows, handleOpen}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    text: "Copy url",
    index: null
  });

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;



  const handleCopy = (val, index) => {
    copyToClipboard(val).then((res) => {
      if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", index });
    })
};




  const columns = [
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      render: (text, row) => (
          <p className="flex flex-col">{moment(text ?? "").format("DD. MMM. YYYY")} 
           <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(row?.createdAt).format('h:mm:ss a')}
          </small>
          </p>
      ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      render: (text, row) => (
          <p className="">NGN {formatNumberOnly(text ?? 0)}</p>
    
      ),
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'email',
      render: (text, row)=>(
        <>
          {row?.schedule?.mandate?.email ?? "N/A"}
        </>
      )
    },
    {
      title: 'TRANSACTION REFERENCE',
      dataIndex: 'reference',
      render: (text, row, index)=>(
        <div className='flex gap-2 items-center'>
          {text ?? "N/A"}
            <span onClick={()=>handleCopy(text ?? "",  index)} className='cursor-pointer'>
                {
                    copy?.state && copy?.index === index?
                    <IoCopy color="#733534" size={"2.5vh"} />
                    :
                    <IoCopyOutline color="#733534" size={"2.5vh"} />
                }
            </span>
        </div>
      )
    },
    {
      title: 'PAYMENT METHOD',
      dataIndex: 'payment_type',
      render: (text)=>(
        <>{text ?? "N/A"}</>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <div className={`capitalize flex gap-x-1 items-center max-w-full ${text==="pending"? "bg-yellow-100 text-yellow-900" : (text==="successful" || text==="success")? "bg-green-100 text-green-900": "bg-red-100 text-red-500" } rounded-full px-2 text-center uppercase font-[500]`}>
            <p className={`h-2 w-2 rounded-full ${text==="pending"? "bg-yellow-700" : (text==="successful" || text==="success")? "bg-green-700": "bg-red-700"}`}></p>
            <i>{text}</i></div>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   render: (text, row)=>(
    //     <>
    //       <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
    //         <IoEyeOutline size={"18px"}/>
    //       </small>
    //     </>
    //   )
    // },
  ];


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));





  return (
    <>
        <Table columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }}/>
    
    </>
  );
};
export default TransactionsTable;