import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import UserLoanApprovalPages from "./pages/Emandates/user_pages_for_loan_approval/UserLoanApprovalPages";
import EmandateDashboard from "./pages/admin/dashboard/Dashboard";
import EmandateProtectedRoute from "./components/route_guard/EmandateProtectedRoute";
import EmandateLayout from "./components/layouts/emandate_layout/EmandateLayout";
import GeneralDrawer from "./components/common/drawer/GeneralDrawer";
import MandateStatus from "./pages/admin/mandate_status/MandateStatus";
import { Transactions } from "./pages/admin/transactions/Transactions";
import { Settings } from "./pages/admin/settings/Settings";
import { Customers } from "./pages/admin/customers/Customers";
import StaffLogin from "./pages/auth/lenderAuth/StaffLogin";
import ForgotPassword from "./pages/auth/lenderAuth/ForgotPassword";
import ResetPassword from "./pages/auth/lenderAuth/ResetPassword";
import ApproveUpdatedScheduleView from "./pages/Emandates/user_pages_for_loan_approval/ApproveUpdatedScheduleView";
import LandingPage from "./pages/LandingPage";

function App() {


  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<StaffLogin />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="user/e_mandate/:orderId"
          element={<UserLoanApprovalPages />}
        />
        <Route
          path="user/e_mandate/await_approval/:orderId"
          element={<ApproveUpdatedScheduleView />}
        />
        <Route path="" element={<EmandateProtectedRoute />}>
          <Route path="" element={<EmandateLayout />}>
            <Route path="dashboard" element={<EmandateDashboard />} />
            <Route path="mandate_status" element={<MandateStatus />} />
            <Route path="customers" element={<Customers />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Route>
      </Routes>

      <GeneralDrawer />
    </>
  );
}

export default App;
