import { ConfigProvider, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import ModalBox from "../../../common/ModalBox";
import ChangePasswordForm from "./ChangePasswordForm";
import OutlineCustomButton from "../../../common/OutlineCustomButton";
import { Input } from "../../../common/form_inputs/Input";
import { useGetProfile } from "../../../../api/getHooks";
import { useProfileData } from "../../../../hooks/useProfileData";
import { useUpdateProfile } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

export const ProfileView = () => {
  const { data, status } = useProfileData();

  const [isLoading, setIsLoading] = useState(false);

  const [formDirty, setFormDirty] = useState(false);

  const [openModal, setOpenModal] = useState(false)

  const { showMessage, contextHolder } = useCustomMessage();

  const mutation = useUpdateProfile();


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      first_name: data?.first_name ?? "",
      last_name: data?.last_name ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      password: "",
      two_FA: "",
    },
  });

  const handleChange = (e, fieldName) => {
    setFormDirty(true);
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };

 


  useEffect(() => {
    if (data) {
      reset({
        first_name: data.first_name ?? "",
        last_name: data.last_name ?? "",
        email: data.email ?? "",
        phone: data.phone ?? "",
        password: "",
        two_FA: "",
      });
    }
  }, [data, reset]);

  const onSubmit = (data) => {
    
    const json = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone: data?.phone,
      email: data?.email,
      profile_picture: "",
    };

    setIsLoading(true);

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);

      },
      onSuccess: (data) => {

        const res = data?.data;

        reset();

        setFormDirty(false);

        showMessage("success", res?.message);

      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };












  const handleOpen=()=>{
    setOpenModal(true)
  }
  const handleClose=()=>{
    setOpenModal(false)
  }



  const discardEdit=()=>{
    reset({
      first_name: data.first_name ?? "",
      last_name: data.last_name ?? "",
      email: data.email ?? "",
      phone: data.phone ?? "",
      password: "",
      two_FA: "",
    });

    setFormDirty(false);
  }


  return (
    <>
      <main className="w-full max-w-3xl">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 md:gap-y-3">
            <div className="mb-3">
              <label htmlFor="first_name" className="font-[400] text-[16px]">
                First Name
              </label>
              <Input
                name="first_name"
                value={getValues("first_name")}
                placeholder="Enter First Name"
                size={"large"}
                className={` text-[14px] ${
                  errors.first_name ? "error" : ""
                }`}
                status={errors.first_name ? "error" : ""}
                {...register("first_name", {
                  required: "First name is required",
                })}
                onChange={(e) => handleChange(e, "first_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.first_name && errors.first_name.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="last_name" className="font-[400] text-[16px]">
                Last Name
              </label>
              <Input
                name="last_name"
                value={getValues("last_name")}
                placeholder="Enter Last Name"
                size={"large"}
                className={` text-[14px] ${
                  errors.last_name ? "error" : ""
                }`}
                status={errors.last_name ? "error" : ""}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                onChange={(e) => handleChange(e, "last_name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.last_name && errors.last_name.message}
              </small>
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="font-[400] text-[16px]">
                Email Address
              </label>
              <Input
                name="email"
                value={getValues("email")}
                type="email"
                placeholder="Enter Email"
                size={"large"}
                className={` text-[14px] ${
                  errors.email ? "error" : ""
                }`}
                status={errors.email ? "error" : ""}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                onChange={(e) => handleChange(e, "email")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.email && errors.email.message}
              </small>
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="font-[400] text-[16px]">
                Phone Number
              </label>
              <Input
                name="phone"
                value={getValues("phone")}
                type="number"
                placeholder="Enter Phone number"
                size={"large"}
                className={` text-[14px] ${
                  errors.phone ? "error" : ""
                }`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                onChange={(e) => handleChange(e, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
          </div>
          <div className="mt-8 mb-10 grid grid-cols-2">
            <div className="flex flex-col gap-y-10">
              <div className="flex justify-between gap-y-4">
                <label htmlFor="" className="font-[400] text-[16px]">
                  Password
                </label>
                <p
                  className="text-[#733534] font-[600] cursor-pointer"
                  onClick={handleOpen}
                >
                  Change Password
                </p>
              </div>
              {/* <div className="flex justify-between gap-y-4">
                <label htmlFor="" className="font-[400] text-[16px]">
                  Two-factor Auth
                </label>

                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#733534",
                    },
                  }}
                >
                  <Switch onChange={(checked) => setValue("two_FA", checked)} />
                </ConfigProvider>
              </div> */}
            </div>
          </div>
          <div className="mt-12 mb-10 flex gap-x-3 max-w-lg mx-auto">
            <OutlineCustomButton className={"w-full"} disabled={!formDirty} onClick={discardEdit}>
              Discard
            </OutlineCustomButton>
            <CustomButton
            type={"submit"}
              disabled={!formDirty}
              className={"w-full"}
              loading={isLoading}
            >
              Save
            </CustomButton>
          </div>
        </form>
      </main>


<ModalBox open={openModal} handleClose={handleClose} footer={null}>
                  <ChangePasswordForm closeModal={handleClose}/>
</ModalBox>



      {contextHolder}







    </>
  );
};
