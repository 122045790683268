import { useGetProfile } from "../api/getHooks";

export const useProfileData=()=>{
    const {data, error} = useGetProfile();




    if(!error){
        const responseData = data?.data?.data
        return {status: true, data: responseData}
    }
    else if(error){
        const msg = error?.response?.data?.message


        return {status: false, msg}
    }
}