import React from 'react'
import { Table as AntTable, ConfigProvider } from "antd"

export const Table = ({className, header_bg, ...rest}) => {
  return (
    <>
    <ConfigProvider 
     theme={{
      components: {
        Table: {
          headerBg: header_bg ?"#3F3E6D": "#CCC6FF24",
          headerColor: header_bg ?"#fff": "",
          borderColor: header_bg ?"#fff": "#f0f0f0",
          headerBorderRadius: "0px",
          rowSelectedHoverBg: "#f0f0f0",
            rowSelectedBg: "#fafafa"
        }
      },
      token: {
        colorPrimary: "#733534",
      }
    }}
    >

         <AntTable
         
               {...rest}
               scroll={{ x: 'max-content' }}
              />

        </ConfigProvider>
    </>
  )
}
