import { Modal } from "antd";

export default function DrawerBox({
  open,
  handleClose,
  children,
  anchor,
  size,
  title,
  ...rest
}) {
  return (
    <>
      <Modal title={title} centered open={open} onCancel={handleClose} {...rest}>
        {children}
      </Modal>
    </>
  );
}
