import { Button } from "antd";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { IoCopy, IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "../../../../utils/copyToClipboard";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useProfileData } from "../../../../hooks/useProfileData";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { Radio } from "antd";
import { useUpdateCompany } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

export const AccountView = () => {
  //external hooks
  const { openDrawer, updateDrawerTitle } = useDrawer();

  const mutation = useUpdateCompany();

  const { showMessage, contextHolder } = useCustomMessage();

  const { data } = useProfileData();

  const { user_permission } = useUserPermission();

  //

  //useStates
  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    text: "Copy url",
    index: null,
  });

  const [cost_on_customer, setCost_on_customer] = useState(data?.company?.cost_on_customer);

  const handleCopy = (val, index) => {
    copyToClipboard(val).then((res) => {
      if (res.status)
        setCopy({ icon: IoCopy, state: true, text: "Copied", index });
    });
  };

  const handleUpdateDetail = () => {
    openDrawer("ADD_ACCOUNT", { company_detail: data?.company });
    updateDrawerTitle("Bank Account");
  };

  const account_cred = [
    {
      label: "Account Number",
      value: data?.company?.account_number ?? "xxxxxxxx",
    },
    { label: "Bank Name", value: data?.company?.bank_name ?? "xxxxxxx" },
  ];


  const onChange=(e)=>{
    setCost_on_customer(e?.target?.value);
    const json = {
      cost_on_customer: e.target?.value
    };

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;


        showMessage("success", res?.message);
      },
    });


  }


  return (
    <>
      <main className="w-full max-w-xl">
        <div className="border rounded-[6px] border-[#E6E8F0] p-5 pb-14">
          <p className="text-[18px] font-[600]">Recipient Account</p>
          <div className="flex justify-between items-center my-12">
            <div className="flex gap-x-4">
              <div className="text-[14px] flex items-center justify-center text-[#733534] font-[600] h-[46px] w-[46px] rounded-[50%] bg-[#F9D7D68F]">
                NGN
              </div>
              <p className="font-[600] text-[20px] capitalize"></p>
            </div>
            <div className="flex">
              <Button
                type="text"
                className="text-[#733534]"
                icon={<CiEdit className="text-[#733534]" />}
                iconPosition="end"
                onClick={handleUpdateDetail}
                disabled={!user_permission?.edit_settings}
              >
                Update
              </Button>
            </div>
          </div>
          <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
          <div className="flex flex-col gap-y-6">
            {account_cred.map((item, index) => (
              <div className="flex justify-between items-center" key={index}>
                <div className="flex items-center gap-x-4">
                  <div className="h-[12px] w-[12px] bg-[#D8DAE5] rounded-[50%]"></div>
                  <div>
                    <p className="font-[500] text-[13px]">{item?.label}</p>
                    <p className="font-[700] text-[15px]">{item?.value}</p>
                  </div>
                </div>
                <span
                  onClick={() => handleCopy(item?.value, index)}
                  className="cursor-pointer"
                >
                  {copy?.state && copy?.index === index ? (
                    <IoCopy color="#733534" size={"3vh"} />
                  ) : (
                    <IoCopyOutline color="#733534" size={"3vh"} />
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5 ms-12">

        <h4 className="font-[500] text-[18px] mb-2">Transaction fee preference</h4>
        <Radio.Group
                onChange={(e) => onChange(e)}
                name="grace_period_type"
                className="mt-2"
                value={cost_on_customer}
              >
                <Radio value={true}>Push the cost of transaction to Customer</Radio>
                <Radio value={false}>Push the cost of transaction to me</Radio>
              </Radio.Group>
        </div>
      </main>


      {contextHolder}
    </>
  );
};
