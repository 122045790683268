import { useParams } from "react-router-dom";
import LoanSummary from "./LoanSummary";
import BVN_OTP from "./BVN_OTP";
import BankAccounts from "./BankAccounts";
import SetMandate from "./SetMandate";
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import BorrowerLayout from "../../../components/layouts/borrower_layout/BorrowerLayout";
import { useGetUserMandate } from "../../../api/getHooks";
import { useEffect } from "react";
import { Result } from "antd";

export default function ApproveUpdatedScheduleView(){

    const params = useParams();
  const orderID = params.orderId;


  const { currentPage, updateCurrentPage, data: {status} } = useUserLoanApproval();

  const {data: data} = useGetUserMandate(orderID);
  const response = data?.data

  const schedules = response?.data








    return(
        <>
        <BorrowerLayout customer_name={`${schedules?.first_name} ${schedules?.last_name}`} company={schedules?.company} awaitAuthorization={true}>
            {
                currentPage==="LOAN_SUMMARY" ? <LoanSummary mandate={schedules} mandateID={orderID} awaitAuthorization={true}/> : currentPage==="RESPONSE_VIEW"? (
                    <div className="mt-10">
                    <Result
                    status={status}
                    title={
                      <p className="font-[500] text-[24px] text-[#2A2A29]">
                       You have approved the new loan schedule
                      </p>
                    }
                    extra={null}
                  />
                    
                    </div>
                ): null
            }
        </BorrowerLayout>
            
        </>
    )
}