import { Input, Spin, Result } from "antd";
import EmandateHistoryTable from "./EmandateHistoryTable";
import { useGetMandates } from "../../../../api/getHooks";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useUserPermission } from "../../../../hooks/useUserPermission";
const { Search } = Input;

export default function EmandateHistory({ handleClick }) {
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const { user_permission, isLoading:permissionLoading, isError: permissionError } = useUserPermission();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { data, isLoading, isError } = useGetMandates({
    status: ['inactive']
  });
  const response = data?.data;
  // console.log(response);

  const handleOpenDrawer = (data) => {
    openDrawer("REPAYMENT_SCHEDULE", { schedule: data });
    updateDrawerTitle("Mandate Status");
  };

  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">
          Recent Mandate
        </h3>
        <div className="flex justify-between mb-3">
          <div>
            <Search
              placeholder="Search Name"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="flex gap-x-2">
            {/* <Select
              defaultValue="last 7 days"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "last 14 days",
                  label: "last 14 days",
                },
                {
                  value: "last 21 days",
                  label: "last 21 days",
                },
                {
                  value: "last 28 days",
                  label: "last 28 days",
                },
              ]}
            /> */}
            {/* <Select
              defaultValue="Feb 14, 2024 Feb 24, 2024"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
              ]}
            /> */}
          </div>
        </div>

        {isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ):
        user_permission?.view_mandate ? (
          <EmandateHistoryTable
            rows={response?.data}
            handleToastClick={handleClick}
            handleOpen={handleOpenDrawer}
          />
        ) : (
          <div className="text-center my-10">
            <i className="text-lg">You are not permitted to view mandates</i>
          </div>
        )}
      </main>
    </>
  );
}
