import business_img from "../../../../assets/business_img.png";
import individual_img from "../../../../assets/individual_img.png";
import CustomButton from "../../../../components/common/CustomButton";

import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";

const MandateType = () => {
  const { openDrawer } = useDrawer();

  const createMandate = (account) => {
    openDrawer("MANDATE_USER_FORM", {mandateType: account}, "MANDATE_TYPE");
  };

  return (
    <>
      <div>
        <h4 className="font-[600] text-[18px] mb-2">Select Mandate type</h4>
        <div className="flex gap-8 flex-wrap">
          <div className="relative w-56">
            <img
              src={business_img}
              alt="Business account"
              class="w-56 h-56 rounded-full object-cover border-2"
            />
            <CustomButton
              className={"absolute bottom-16 left-7"}
              type="submit"
              onClick={()=>createMandate("business")}
            >
              Business account
            </CustomButton>
          </div>
          <div className="relative w-56">
            <img
              src={individual_img}
              alt="Business account"
              class="w-56 h-56 rounded-full object-cover border-2"
            />
            <CustomButton
              className={"absolute bottom-16 left-7"}
              type="submit"
              onClick={()=>createMandate("individual")}
            >
              Individual account
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default MandateType;
