
import { useLoanDrawer } from "../../../hooks/zustand_hooks/useLoanDrawer";
import DrawerBox from "../DrawerBox";
import DebitAccount from "../../specific/admin_side_loan/loan/DebitAccount";
import LoanDetail from "../../specific/admin_side_loan/loan/LoanDetail";
import SingleLoanDetail from "../../specific/admin_side_loan/loan/SingleLoanDetail";
import SuccessfullDebit from "../../specific/admin_side_loan/loan/SuccessfullDebit";

export default function LoanWithDrawers(){
    const {isOpen, closeDrawer, pageTitle, data} = useLoanDrawer();
    return(
        <>
             <DrawerBox open={isOpen} handleClose={closeDrawer} size={'50rem'} title={<p className="text-center">{data?.user?.name}</p>}>
        {
            pageTitle === "LOAN_DETAIL" ? <LoanDetail /> : pageTitle === "SINGLE_LOAN_DETAIL" ? <SingleLoanDetail /> : pageTitle === "DEBIT_ACCOUNT" ? <DebitAccount /> : pageTitle === "DEBIT_RESULT" ? <SuccessfullDebit /> : null
        }
        </DrawerBox>
        </>
    )
}