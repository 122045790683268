import { Input, Select, Spin, Result } from "antd";
import React from "react";
import CustomerListTable from "../../../components/common/table/customer_table/CustomerListTable";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { useGetCustomers } from "../../../api/getHooks";
import { useUserPermission } from "../../../hooks/useUserPermission";

const { Search } = Input;

export const Customers = () => {
  const { data, isLoading, isError } = useGetCustomers(1); //it will fetch the first page on initial

  const customers = data?.data?.data;

  const { user_permission, isError: permissionError, isLoading: permissionLoading } = useUserPermission();

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const handleOpenDrawer = (data) => {
    openDrawer("CUSTOMER_SCHEDULE", { user: data });
    updateDrawerTitle(`${data?.first_name} ${data?.last_name}`);
  };

  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Customers</h3>
        <div className="flex justify-between mb-3">
          <div>
            <Search
              placeholder="Search Name"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <Select
              defaultValue="last 7 days"
              style={{
                width: 120,
              }}
              options={[
                {
                  value: "last 14 days",
                  label: "last 14 days",
                },
                {
                  value: "last 21 days",
                  label: "last 21 days",
                },
                {
                  value: "last 28 days",
                  label: "last 28 days",
                },
              ]}
            />
            <Select
              defaultValue="Feb 14, 2024 Feb 24, 2024"
              style={{
                width: 120,
              }}
              options={[
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
              ]}
            />
          </div>
        </div>

        {isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : user_permission?.view_customers ? (
          <CustomerListTable rows={customers} handleOpen={handleOpenDrawer} />
        ) : (
          <div className="text-center my-10">
            <i className="text-lg">You are not permitted to view customers</i>
          </div>
        )}
      </main>
    </>
  );
};
