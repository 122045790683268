import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PatchMethod, PostMethod, PostMethodWithHeader, PutMethod, GetMethod, PutMethodWithHeader } from ".";
import { API_URL } from "./api_urls";
import adminAuth from "../components/route_guard/adminAuth";


export const useCheckPayment = (scheduleID) => {
    const token = localStorage.getItem("staffAccessToken");
    const verifyMandate = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.verify_mandate+scheduleID,
        );
      }
    });
    return verifyMandate;
  };




  //create mandate

export const useCreateMandate = () => {
    const token = localStorage.getItem("staffAccessToken");
    const createMandate = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(
          API_URL.create_mandate,
          token,
          payload
        );
      }
    });
    return createMandate;
  };


  //debit account
export const useDebitAccount = () => {
    const token = localStorage.getItem("staffAccessToken");
    const debitAcc = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(
          API_URL.debit_account,
          token,
          payload
        );
      }
    });
    return debitAcc;
  };

  //admin login
export const useStaffLogin = () => {
    const login = useMutation({
      mutationFn: (payload) => {
        return PostMethod(API_URL.login, payload);
      },
    });
    return login;
  };

  //fprgot password
export const useForgottenPsw = () => {
    const forgotten_password = useMutation({
      mutationFn: (payload) => {
        return PostMethod(API_URL.forgotten_password, payload);
      },
    });
    return forgotten_password;
  };
  
  //reset password
export const useResetPsw = () => {
    const reset_password = useMutation({
      mutationFn: (payload) => {
        return PostMethod(API_URL.reset_password, payload);
      },
    });
    return reset_password;
  };


  //register company
  export const useRegisterCompany=()=>{
    const token = adminAuth().token;
    const registerCompany = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(API_URL.register_company, token ,payload);
      },
    })

    return registerCompany;
  }
  //add staff
  export const useAddStaff=(state, staffID)=>{
    const queryClient = useQueryClient();
    const token = adminAuth().token;
    const addStaff = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(state==='edit'? API_URL.edit_staff+staffID : API_URL.add_staff, token ,payload);
      },
      onSuccess:()=>{
        queryClient.invalidateQueries("staffs")
      }
    })

    return addStaff;
  }
  //update permission
  export const useUpdatePermission=()=>{
    const queryClient = useQueryClient();
    const token = adminAuth().token;
    const updatePermission = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(API_URL.update_permission, token ,payload);
      },
      onSuccess:()=>{
        queryClient.invalidateQueries("positions")
      }
    })

    return updatePermission;
  }
  //update profile
  export const useUpdateProfile=()=>{
    const queryClient = useQueryClient();
    const token = adminAuth().token;
    const updateProfile = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(API_URL.update_profile, token ,payload);
      },
      onSuccess:()=>{
        queryClient.invalidateQueries("get_profile")
      }
    })

    return updateProfile;
  }



  export const useApproveMandate = (mandateID) => {
    const approveMandate = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.approve_mandate+mandateID,
        );
      }
    });
    return approveMandate;
  };

  export const useUpdateCompany = () => {
    const queryClient = useQueryClient();
    const updateCompany = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.edit_company,
          payload
        );
      },
      onSuccess:()=>{
        queryClient.invalidateQueries("get_profile")
      }
    });
    return updateCompany;
  };
  export const useChangePsw = () => {
    const queryClient = useQueryClient();
    const changePsw = useMutation({
      mutationFn: (payload) => {
        return PostMethodWithHeader(
          API_URL.change_password,
          adminAuth()?.token,
          payload
        );
      },
      onSuccess:()=>{
        queryClient.invalidateQueries("get_profile")
      }
    });
    return changePsw;
  };

  export const useMandateRequired = () => {
    const mandateRequired = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.requireMandate,
          payload
        );
      },
    });
    return mandateRequired;
  };

  export const useConfirmPayment = () => {
    const confirmPayment = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.confirmPayment,
          payload
        );
      },
    });
    return confirmPayment;
  };
  export const useCancelSchedule = () => {
    const cancelSchedule = useMutation({
      mutationFn: (payload) => {
        return PutMethod(
          API_URL.cancelSchedule,
          payload
        );
      },
    });
    return cancelSchedule;
  };
  export const useUpdateSchedule = (mandateID) => {
    const updateSchedule = useMutation({
      mutationFn: (payload) => {
        return PutMethodWithHeader(
          API_URL.update_schedule+mandateID,
        adminAuth()?.token,
          payload
        );
      },
    });
    return updateSchedule;
  };



  export const useCheckBalance = () => {
    const check_balance = useMutation({
      mutationFn: (accountID) => {
        return GetMethod(
          API_URL.account_balance + accountID, "ADMIN"
        );
      }
    });
    return check_balance;
  };

  export const useRevalidateMandate=()=>{
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn:(payload)=>{
      queryClient.refetchQueries({
        queryKey: ["all_mandates", payload]
      })
      
    }
   })
}