import { useMutation } from "@tanstack/react-query"
import { PostMethodWithFile, PostMethod, GetMethod, PatchMethod } from "."
import { API_URL } from "./api_urls"

export const useSendSchedule=()=>{
    const sendSchedule= useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithFile(API_URL.initiate_mandate, payload)
        }
    })
    return sendSchedule
}


export const useAuthorizeMandate = (mandateID) => {
    const authorizeMandate = useMutation({
      mutationFn: (payload) => {
        return GetMethod(
          API_URL.authorize_mandate + mandateID, "USER"
        );
      }
    });
    return authorizeMandate;
  };

export const useMandateAcc = () => {
    const madnateAcc = useMutation({
      mutationFn: (accountID) => {
        return GetMethod(
          API_URL.get_account_mandate + accountID, "USER"
        );
      }
    });
    return madnateAcc;
  };


export const useVerifyBVN = () => {
    const verifyBvn = useMutation({
      mutationFn: (payload) => {
        return PostMethod(
          API_URL.verify_bvn_authorization, payload
        );
      }
    });
    return verifyBvn;
  };
export const useAthorizeReschedule = (orderID) => {
    const authorizeReschedule = useMutation({
      mutationFn: (payload) => {
        return PatchMethod(
          API_URL.authorize_reschedule+orderID, payload
        );
      }
    });
    return authorizeReschedule;
  };