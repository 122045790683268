import { useCallback, useMemo, useState } from "react";
import { formatNumberOnly } from "../../../../../utils/formatNumber";
import CustomButton from "../../../../common/CustomButton";
import { Button, Checkbox } from "antd";
import { RxCheckCircled } from "react-icons/rx";
import { PiWarningCircle } from "react-icons/pi";
import { useDrawer } from "../../../../../hooks/zustand_hooks/useDrawer";
import { useGetMandateSchedule } from "../../../../../api/getHooks";
import { IoCopy, IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "../../../../../utils/copyToClipboard";
import { useApproveMandate, useMandateRequired, useRequireMandate } from "../../../../../api/admin_mutation";
import useCustomMessage from "../../../../../hooks/zustand_hooks/useCustomMessage";
import { formatDate } from "../../../../../utils/formatDate";
import { useUserPermission } from "../../../../../hooks/useUserPermission";
import { useQueryClient } from "@tanstack/react-query";
import Confirm from "../../../../common/Confirm";


export default function LoanRepaymentSchedule() {
  // const schedules = response?.data;

  const [isLoading, setIsLoading] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);



  const {openDrawer, updateDrawerTitle, data: {schedule}} = useDrawer();

  const { user_permission } = useUserPermission();

  const { showMessage, contextHolder } = useCustomMessage();


  const queryClient = useQueryClient();
  const mutation = useApproveMandate(schedule?.id);

  const mutation2 = useMandateRequired();





  const { data } = useGetMandateSchedule(schedule?.id);

  const accounts = data?.data?.data?.accounts
  const schedules = data?.data?.data?.schedules




  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    id: null,
    text: "Copy url",
  });

  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  //copying to clippoard
  const handleCopy = (val, index) => {
      copyToClipboard(`${baseUrl}/user/e_mandate/${schedule?.order_id}`)?.then((res) => {
        if(res.status) setCopy({ icon: IoCopy, state: true, text: "Copied", id: schedule?.id });
      })
  };


  const handleApproveMandate=()=>{
    setOpenConfirm(true)
  }

  const confirmAction=()=>{
    setIsLoading(true);
    mutation.mutate("", {
      onSuccess:(data)=>{
        openDrawer("MANDATE_ACTIVATION_RESPONSE", {message: `Mandate for ${schedule?.first_name} ${schedule?.last_name} has been successfully activated`})
      },
      onError:(err)=>{
        const error = err?.response?.data?.message ?? err?.message;


        showMessage("error", error)
      },
        onSettled:()=>{
          setIsLoading(false);
        }
    })

  }



  const updateAccount=(e, accountID)=>{

    const json = {
      "status":e.target?.checked,
      "account_id":accountID
  }
    mutation2.mutate(json, {
      onSuccess:()=>{
        queryClient.invalidateQueries(["mandate_schedule", schedule?.id])
      }
    })
  }



  const activeAccount = useCallback((status)=>{
      if(status==='active'){
        return true
      }
      else{
        return false
      }
  }, [])



  const totalBalance = useMemo(() => {
    const totalSum = schedules?.reduce((acc, curr) => acc + (parseFloat(curr?.total_amount_due) || 0), 0);
    return totalSum;
  }, [schedules]);





  return (
    <>
      <main className="flex items-center justify-center mt-4">
        <div className="w-full max-w-2xl px-1 py-4 rounded-lg bg-white shadow-sm">
          <div className=" grid grid-cols-2 lg:gap-x-8 md:gap-x--8 gap-x-4 gap-y-1 items-center">
              <h4 className="font-[600] text-[18px] mb-2">
                Loan Repayment Schedule
              </h4>
              <p>
              <span
              className="flex gap-x-1 text-[#733534] cursor-pointer"
              onClick={handleCopy}
            >
              <copy.icon color="#733534" size={"3vh"} />{" "}
              {copy?.id===schedule?.id && copy?.state ? "Copied" : "Copy url"}
            </span> 
              </p>
          </div>
          <div className="flex flex-col gap-y-1">
            <p className="font-[500] text-[16px]">
              {schedule?.first_name} {schedule?.last_name}
            </p>
            <div className="grid grid-cols-2 lg:gap-x-8 md:gap-x--8 gap-x-4 gap-y-1">
              <div className="flex flex-col gap-y-3">
                <p className="font-[500] text-[14px]">
                  BVN: <span className="font-[300]">{schedule?.bvn}</span>
                </p>
                <p className="font-[500] text-[14px]">
                  Date: <span className="font-[300]">{formatDate(schedule?.start_date)}</span>
                </p>
                <p className="font-[500] text-[14px]">
                  Phone: <span className="font-[300]">{schedule?.phone}</span>
                </p>
                <p className="font-[500] text-[14px]">
                  Email: <span className="font-[300]">{schedule?.email}</span>
                </p>

              </div>
              <div className="flex flex-col gap-y-3">
              <p className="font-[500] text-[14px]">
                Loan Amount:{" "}
                <span className="font-[300]">
                  NGN {formatNumberOnly(parseFloat(schedule?.total_amount) - parseFloat(schedule?.total_paid))}
                </span>
              </p>
              <p className="font-[500] text-[14px]">
                  Tenor:{" "}
                  <span className="font-[300]">
                    {schedule?.tenor} {schedule?.payment_type}
                  </span>
                </p>
                <p className="font-[500] text-[14px]">
                  Total Balance: <span className="font-[300]">NGN {formatNumberOnly(parseFloat(totalBalance))}</span>
                </p>
              </div>
            </div>

            {/* <div className="flex flex-col gap-y-1 mt-4">
              <p className="font-[500] text-[14px]">
                Loan Amount:{" "}
                <span className="font-[300]">
                  NGN {formatNumberOnly(50000)}
                </span>
              </p>
              <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1">
                <p className="font-[500] text-[14px]">
                  Tenor:{" "}
                  <span className="font-[300]">
                    {schedule?.tenor} {schedule?.payment_type}
                  </span>
                </p>
                <p className="font-[500] text-[14px]">
                  Total Interest Amount:{" "}
                  <span className="font-[300]">{5000}</span>
                </p>
              </div>
            </div>
            <div className="mt-8 mb-3">
              <div className="grid md:grid-cols-3 grid-cols-1 lg:gap-x-8 gap-x-4 gap-y-1">
               
                <p className="font-[500] text-[14px]">
                  Total Balance: <span className="font-[300]">{55000}</span>
                </p>
              </div>
            </div> */}
          </div>
          <div className="mt-3 flex flex-col gap-y-4">
            {accounts?.map((item, index) => {
              return (
                <div
                  className="flex md:flex-row my-4 flex-col justify-between gap-3 md:gap-14 md:grid md:grid-cols-2"
                  key={index}
                >
                  <div className="flex justify-between pe- items-end border-b w-full border-b-[#CFCDCC]">
                    <div className="flex gap-x-2 items-end">
                      {
                        item?.initiate_mandate_data?.icon && (
                            <img
                              src={
                                item?.initiate_mandate_data?.icon
                                }
                              alt={"bank_url"}
                              width={50}
                            />
                        )
                      }
                      <p className="text-[16px]">
                        {item?.initiate_mandate_data?.bank}
                      </p>
                    </div>
                    <p className="font-[700] text-[16px]">
                      {item?.initiate_mandate_data?.account_number}
                    </p>{" "}
                  </div>
                  <div>
                    <p
                      className={`flex gap-x-1 font-[300] ${
                        activeAccount(item?.status) ? "text-green-500" : "text-[#733534]"
                      }`}
                    >
                      {activeAccount(item?.status) ? (
                        <RxCheckCircled color="green" size={"3vh"} />
                      ) : (
                        <PiWarningCircle color="#733534" size={"3vh"} />
                      )}

                      {activeAccount(item?.status)
                        ? "Mandate Authorized"
                        : "Mandate not Authorized"}
                    </p>
                    {!activeAccount(item?.status) && (
                      <Checkbox onChange={(e) => updateAccount(e, item?.id)} className="mx-5 mt-2">
                        Request Mandate authorization
                      </Checkbox>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {
            schedule?.status !== "active" && (

          <div className="mt-8 max-w-md flex flex-col mx-auto gap-y-3 justify-center items-center">
            <CustomButton className={"w-full"} onClick={handleApproveMandate} 
            // loading={isLoading}
             disabled={!accounts?.length || !user_permission?.approve_mandate}>
              Approve
            </CustomButton>
            {/* <Button
              size="large"
              loading={false}
              disabled={!accounts?.length || !user_permission?.approve_mandate}
              className="rounded-[8px] w-full border-[#8B0404] text-[#8B0404] text-[18px]  hover:!border-[#8B0404] hover:!text-[#8B0404]"
            >
              Cancel
            </Button> */}
          </div>
            )
          }

<Confirm openConfirm={openConfirm} confirmLoading={isLoading} setOpenConfirm={setOpenConfirm} message={ "Are you sure to confirm this action?"} handleConfirm={confirmAction} />
        </div>
      </main>


      {contextHolder}
    </>
  );
}
