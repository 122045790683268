import {jwtDecode} from "jwt-decode";
import { refreshTokenFn } from "../api/fetchingFn";

const currentTime = Date.now();
export const isAccessTokenValid = (token) => {
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode(token);

    // Check if the token has an expiration claim
    if (decodedToken.exp) {
      // expiration timestamp in seconds

      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
  
      
      if((expirationTime-currentTime)<=5000){ //if the current time minus the expired time is equal or less than 5second, it will call the refresh token
        const refreshToken = localStorage.getItem('refreshToken');
      //access token expired, will refresh the token here
      refreshTokenFn(refreshToken)
        .then((res) => {
          const token = res?.data?.data?.token;
          localStorage.setItem("accessToken", token?.accessToken);
          localStorage.setItem("refreshToken", token?.refreshToken);
        })
        .catch((err) => {
          console.log(err?.response?.data?.message, "error")
        });
      }
      // Compare the expiration time with the current time
      return expirationTime > currentTime;
    }
    return false;
  };
export const isRefreshTokenValid = (token) => {
    if (!token) {
      return false;
    }
    const decodeToken = jwtDecode(token);
    if (decodeToken?.exp) {
      const expiredTime = decodeToken.exp * 1000;
      return expiredTime > currentTime;
    }
    return false;
  };


  export function isTokenAboutToExpire(token, thresholdSeconds = 10) {
    // Decode the JWT payload
    let payload;
    if(token){
      payload = JSON.parse(atob(token?.split('.')[1]));
    }
  
    // Get the expiration timestamp from the payload
    const expirationTimestamp = payload?.exp * 1000; // Convert to milliseconds
  
    // Get the current timestamp
    const currentTimestamp = Date.now();
  
    // Calculate the remaining time until expiration
    const remainingTime = expirationTimestamp - currentTimestamp;

  // console.log(remainingTime/(1000*60*60))
    // Check if the remaining time is less than or equal to the threshold
    return remainingTime <= thresholdSeconds * 1000; // Convert threshold to milliseconds
  }
  