export const formatMoney = (value) => {
    //the value must be a type number
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
  }).format(+value);
  }


  // export const formatNumberOnly=(value)=>{
  //   const nValue = Number(value)
  //   return nValue?.toLocaleString(undefined, { style: 'decimal'})
  // }


  export const formatNumberOnly = (value) => {
    const nValue = Number(value);
    if (isNaN(nValue)) return value; // Handle invalid numbers
  
    // Apply toFixed(2) before converting to locale string
    return nValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  export const formatNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
  
      // Format the value with commas for thousands separator
      return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };