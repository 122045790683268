import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import EmandateSidebar from '../../specific/admin_side_loan/sidebar/EmandateSidebar';
import useWindowSize from '../../../hooks/useWindowSize';
import "./emandateLayout.css";

export default function EmandateLayout({handleClick}) {
    const [navIsOpen, setNavIsOpen] = useState(true);
    const windowSize = useWindowSize({});
    const handleOpenNav=(param)=>{
        setNavIsOpen(param);
    }
    useEffect(()=>{
      if(window.innerWidth<768){
          setNavIsOpen(false);
      }
    }, [])

  

  return (
    <>
        <main style={{backgroundColor: "#FFFFFF"}}>
          
            <EmandateSidebar visible={navIsOpen} show={handleOpenNav} handleClick={handleClick}/>
            <section className={`py-5 md:!px-5 !px-3 ${!navIsOpen? "page": "page page-with-navbar"}`} onClick={()=>{
              if(windowSize.width<768){
                navIsOpen && handleOpenNav(false)
              }
            }}>
                <Outlet />
            </section>
        </main>
    </>
  )
}
