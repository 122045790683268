import { Result } from "antd";
import CustomButton from "./CustomButton";
import { useDrawer } from "../../hooks/zustand_hooks/useDrawer";

function ResponseResultView({title, handleOk}) {
  const { closeDrawer, data: {message} } = useDrawer();

  const handleTerminate = () => {
    closeDrawer();
  };

  return (
    <>
      <Result
        status="success"
        title={
          <p className="font-[700] text-[24px]">
            {message ?? ""}
          </p>
        }
        extra={[
          <CustomButton className={"w-full max-w-lg"} onClick={handleTerminate}>
            Ok
          </CustomButton>,
        ]}
      />
    </>
  );
}

export default ResponseResultView;
