import {useEffect} from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../libs/routes';
import { isTokenAboutToExpire } from '../../utils/validateToken';
import adminAuth from './adminAuth';
import { refreshTokenFn } from '../../api/fetchingFn';

export default function EmandateProtectedRoute() {
    // console.log(adminProfileData)
    const navigate = useNavigate();
    const auth = adminAuth();
    const refreshToken = localStorage.getItem('staffRefreshToken');

    const accessToken = auth?.token;
    const thresholdSeconds = 10; // time interval
      useEffect(() => {
      const checkTokenExpiration = () => {
        if (isTokenAboutToExpire(accessToken, thresholdSeconds)) {
          // Token is about to expire or has already expired
          // Perform token refresh or handle accordingly
          refreshTokenFn(refreshToken, "admin")
          .then((res) => {
            const token = res?.data?.data?.token;
            // console.log(token)
            localStorage.setItem("staffAccessToken", token?.accessToken);
            localStorage.setItem("staffRefreshToken", token?.refreshToken);
          })
          .catch((err) => {
             
              localStorage.removeItem("staffAccessToken");
              localStorage.removeItem("staffRefreshToken");
              navigate(ROUTES.staff_login);
          });
          // console.log("Token is about to expire or has expired.");
        } else {
          // Token is still valid
          // console.log("Token is still valid.");
        }
      };
    
      // Call the function when the component mounts
      checkTokenExpiration();
    
      // Optionally, you can set up a timer to periodically check token expiration
      const intervalId = setInterval(checkTokenExpiration, 3000);
    
      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [accessToken]);

    return auth.status ? <Outlet /> : <Navigate to={ROUTES.staff_login} />;
}
