import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../common/CustomButton";
import { useFormikContext } from "formik";
import {
  CheckMarkIcon,
  EnvelopeIcon,
  SmsIcon,
  WhatsAppIcon,
} from "../../../../utils/svg_icons";
import { IoCopyOutline } from "react-icons/io5";
import { IoCopy } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineSms } from "react-icons/md";
import { useState } from "react";
import { copyToClipboard } from "../../../../utils/copyToClipboard";
import { WhatsappShareButton, EmailShareButton } from "react-share";
import SmsShareButton from "../../../common/SmsShareButton";

export default function MandateCreatedSuccessfull() {
  const navigate = useNavigate();

  const {
    data: { schedules },
    closeDrawer,
  } = useDrawer();

  const [messageApi, contextHolder] = message.useMessage();

  const [copy, setCopy] = useState({
    icon: IoCopyOutline,
    state: false,
    text: "Copy url",
  });

  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  // const { resetForm } = useFormikContext();

  //copying to clippoard
  const handleCopy = (val, index) => {
    copyToClipboard(`${baseUrl}/user/e_mandate/${schedules?.order_id}`)?.then(
      (res) => {
        if (res.status) setCopy({ icon: IoCopy, state: true, text: "Copied" });
      }
    );
  };

  const backHome = () => {
    closeDrawer();
    navigate("/");
  };

  const platforms = [
    {
      shareComponent: EmailShareButton,
      url: `${baseUrl}/user/e_mandate/${schedules?.order_id}`,
      label: "E-mail",

      icon: EnvelopeIcon,
    },
    {
      shareComponent: WhatsappShareButton,
      url: `${baseUrl}/user/e_mandate/${schedules?.order_id}`,
      label: "WhatsApp",
      icon: WhatsAppIcon,
    },
    {
      shareComponent: SmsShareButton,
      url: `${baseUrl}/user/e_mandate/${schedules?.order_id}`,
      label: "SMS",
      icon: SmsIcon,
    },
  ];

  return (
    <>
      <main className="flex justify-center items-center flex-col gap-y-6 h-[70vh]">
        <CheckMarkIcon />
        <p className="font-[700] text-[24px]">
          Mandate request successful created
        </p>
        <div className="flex flex-col gap-y-3">
          <p className="text-[16px] font-[400]">Share request with customer</p>
          <div>
            <span
              className="flex gap-x-1 text-[#733534] cursor-pointer"
              onClick={handleCopy}
            >
              <copy.icon color="#733534" size={"3vh"} />{" "}
              {copy?.state ? "Copied" : "Copy url"}
            </span>

            <div className="flex gap-x-5 mt-4">
              {platforms.map((item, index) => (
                <item.shareComponent
                  url={item?.url}
                  title="Link to complete your E-mandate"
                >
                  <span className="flex gap-x-1 text-[#733534] cursor-pointer">
                    <item.icon color="#733534" size={"3vh"} />
                    {item.label}
                  </span>
                </item.shareComponent>
              ))}
              {/* <span className="flex gap-x-1 text-[#733534] cursor-pointer">
                <SmsIcon color="#733534" size={"3vh"} />
                SMS
              </span> */}
            </div>
          </div>

          <div className="mt-3">
            <CustomButton
              className={"w-[100%]"}
              onClick={backHome}
              loading={false}
            >
              Back to Homepage
            </CustomButton>
          </div>
        </div>
      </main>

      {contextHolder}
    </>
  );
}
