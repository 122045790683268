import { Result } from "antd";
  import { useNavigate } from "react-router-dom";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { ROUTES } from "../../../../libs/routes";
import CustomButton from "../../../common/CustomButton";
  
export default function SuccessfullDebit(){

  const {data: {status, redirect_name, message}, openDrawer, closeDrawer} = useDrawer();

    const navigate = useNavigate();

    const backHome=()=>{
      if(status==='success'){
        closeDrawer();
        navigate(ROUTES.mandate_status)
      }
      else{
        openDrawer(redirect_name, {message: "just redirected"}) // I just add the message(justt redirected) there, I actually don't use it
      }
    }


    return(
        <>
            <Result
        status={status}
        title={
          <p className="font-[300] text-[18px]">
            {message}
          
          </p>
        }
        extra={[
          <CustomButton className={"w-full max-w-lg"} onClick={backHome}>
            Ok
          </CustomButton>,
        ]}
      />


        </>
    )
}