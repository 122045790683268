import { Input, Spin, Result } from "antd";
import { useGetMandates } from "../../../api/getHooks";
import { useRevalidateMandate } from "../../../api/admin_mutation";
import LoanWithDrawers from "../../../components/common/drawer/LoanWithDrawers";
import MandateStatusTable from "../../../components/specific/admin_side_loan/MandateStatusTable";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import { exportExcel } from "../../../utils/exportExcel";
import { useQueryClient } from "@tanstack/react-query";
import { Select } from "../../../components/common/form_inputs/Select";

const { Search } = Input;

export default function MandateStatus() {
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const queryClient = useQueryClient();

  const revalidateMandate = useRevalidateMandate();

  const handleChange = (value) => {
    // revalidateMandate.mutate({
    //   status: ['inactive']
    // })

    const payload = {
      status: ['inactive']
    }

    queryClient.invalidateQueries({queryKey: ["all_mandates", payload]})
  };

  const { data, isLoading, isError } = useGetMandates({
    status: ['active', 'part debited', 'completed', 'canceled', 'confirmed', "reschedule pending", 'reschedule approved', 'reschedule declined']
  });


  console.log(data, isLoading)

  const response = data?.data;

  const handleOpenDrawer = (data) => {
    openDrawer("LOAN_DETAIL", { mandate: data});
    updateDrawerTitle("Mandate");
  };

  const exportAs = (value) => {
    exportExcel({
      excelData: response?.data,
      fileName: `all mandates`,
    });
  };
  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Mandate</h3>
        <div className="flex justify-between mb-3 gap-3 flex-wrap">
          <div>
            <Search
              placeholder="Search Name"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <Select
            size="small"
              defaultValue="last 7 days"
              className=""
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "last 14 day",
                  label: "last 14 days",
                },
                {
                  value: "last 21 da",
                  label: "last 21 days",
                },
                {
                  value: "last 28 d",
                  label: "last 28 days",
                },
              ]}
            />
            <Select
            size="small"
              defaultValue="Feb 14, 2024 Feb 24, 2024"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "Feb 14, 2024 Feb 24, 202",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 20",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
              ]}
            />
            <Select
            size="small"
              defaultValue="Export as:"
              style={{
                width: 120,
              }}
              onChange={exportAs}
              options={[
                {
                  value: "excel",
                  label: "Export as excel",
                },
                // {
                //   value: "pdf",
                //   label: "PDF",
                // },
              ]}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ): isError? (
          <Result
          status="error"
          title="Unexpected error occurred"
           extra={null}
        />
        ) : (
          <MandateStatusTable
            rows={response?.data}
            handleOpen={handleOpenDrawer}
          />
        )}
      </main>

      <LoanWithDrawers />
    </>
  );
}
