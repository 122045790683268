import { useGetPositions } from "../api/getHooks";

export const useStaffPosition=()=>{
    const {data, error, isLoading} = useGetPositions();




    if(!error){
        const responseData = data?.data?.data
        return {status: true, data: responseData, isLoading}
    }
    else if(error){
        const msg = error?.response?.data?.message ?? error?.message


        return {status: false, msg, isLoading}
    }
}