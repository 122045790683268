import { Modal } from "antd";
import React from "react";

const Confirm = ({
  handleConfirm,
  confirmLoading,
  openConfirm,
  setOpenConfirm,
  message,
  title,
}) => {
  return (
    <>
      <Modal
        title={title ?? "Confirmation"}
        open={openConfirm}
        onOk={handleConfirm}
        confirmLoading={confirmLoading}
        onCancel={() => setOpenConfirm(false)}
      >
        <p>{message}</p>
      </Modal>
    </>
  );
};

export default Confirm;
