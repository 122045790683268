// import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
import { useInitiateMandate } from "../../../hooks/zustand_hooks/useInitiateMandate";
import DrawerBox from "../DrawerBox";
import LoanMandateInformation from "../../specific/admin_side_loan/mandateForm/LoanMandateInformation";
import MandateCreatedSuccessfull from "../../specific/admin_side_loan/mandateForm/MandateCreateSuccessfull";
import MandateSummary from "../../specific/admin_side_loan/mandateForm/MandateSummary";
import PenalChargeView from "../../specific/admin_side_loan/mandateForm/PenalChargeView";

export default function InitiateMandateDrawer({handleClick}){
    const {isOpen, closeDrawer, pageTitle, data: {formik}} = useInitiateMandate();
    return(
        <>
             <DrawerBox open={isOpen} handleClose={closeDrawer} size={'50rem'} title={<p className="text-center">New Mandate</p>}>
        {
            pageTitle === "MANDATE_INFO" ? <LoanMandateInformation /> : pageTitle === "MANDATE_SUMMARY" ? <MandateSummary /> : pageTitle === "SET_PENAL_CHARGE"? <PenalChargeView /> : pageTitle === "CREATE_SUCCESSFULL" ? <MandateCreatedSuccessfull handleClick={handleClick}/> : null
        }


        </DrawerBox>
        </>
    )
}