import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import OutlineCustomButton from "../../../common/OutlineCustomButton";
import { Input } from "../../../common/form_inputs/Input";
import { Select } from "../../../common/form_inputs/Select";
import { TextArea } from "../../../common/form_inputs/TextArea";
import { useProfileData } from "../../../../hooks/useProfileData";
import { useEffect, useState } from "react";
import { useUpdateCompany } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useUserPermission } from '../../../../hooks/useUserPermission';

export const BusinessInfoView = () => {
  const { data } = useProfileData();

  const [isLoading, setIsLoading] = useState(false);

  const [formDirty, setFormDirty] = useState(false);

  const { showMessage, contextHolder } = useCustomMessage();

  const { user_permission} = useUserPermission()

  const mutation = useUpdateCompany();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      category: "",
      address: "",
      phone: "",
      location: "",
      description: "",
    },
  });

  useEffect(() => {
    reset({
      name: data?.company?.name,
      category: data?.company?.category,
      address: data?.company?.address,
      phone: data?.company?.phone,
      location: data?.company?.location,
      description: data?.company?.description,
    });
  }, [data, reset]);

  const onSubmit = (data) => {
    const json = {
      name: data?.name,
      category: data?.category,
      address: data?.address,
      phone: data?.phone,
      location: data?.location,
      description: data?.description,
    };

    setIsLoading(true);

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset();

        setFormDirty(false);

        showMessage("success", res?.message);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  const handleChange = (value, fieldName) => {
    setFormDirty(true);
    setValue(fieldName, value);
    trigger(fieldName);
  };


  const discardEdit=()=>{
    reset({
      name: data?.company?.name,
      category: data?.company?.category,
      address: data?.company?.address,
      phone: data?.company?.phone,
      location: data?.company?.location,
      description: data?.company?.description,
    });

    setFormDirty(false);
  }

  return (
    <>
      <main className="w-full max-w-3xl">
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-5 md:gap-y-3">
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Trading/Business name
              </label>
              <Input
                name="name"
                value={getValues("name")}
                placeholder="FI lender"
                size={"large"}
                className={` text-[14px] ${errors.name ? "error" : ""}`}
                status={errors.name ? "error" : ""}
                {...register("name", {
                  required: "First name is required",
                })}
                disabled={!user_permission?.edit_settings}
                onChange={(e) => handleChange(e.target.value, "name")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.name && errors.name.message}
              </small>
            </div>
            <div className="mb-3 flex flex-col">
              <label htmlFor="" className="font-[400] text-[16px]">
                Industry/Category
              </label>
              <Select
                value={getValues("category")}
                onSelect={(value) => handleChange(value, "category")}
                options={[
                  {
                    value: "fintech",
                    label: "fintech",
                  },
                  {
                    value: "e-commerce",
                    label: "E-commerce",
                  },
                  {
                    value: "loan",
                    label: "Loan",
                  },
                ]}
                className={""}
                disabled={!user_permission?.edit_settings}
                status={errors.category ? "error" : ""}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="font-[400] text-[16px]">
                Phone Number
              </label>
              <Input
                name="phone"
                value={getValues("phone")}
                type="number"
                placeholder="Enter Phone number"
                size={"large"}
                className={` text-[14px] ${
                  errors.phone ? "error" : ""
                }`}
                status={errors.phone ? "error" : ""}
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^0\d{10}$/,
                    message:
                      "Phone number must start with 0 and be 11 digits long",
                  },
                })}
                disabled={!user_permission?.edit_settings}
                onChange={(e) => handleChange(e.target.value, "phone")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.phone && errors.phone.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Business address
              </label>
              <Input
                name="address"
                value={getValues("address")}
                placeholder="FI lender"
                size={"large"}
                className={` text-[14px] ${
                  errors.address ? "error" : ""
                }`}
                status={errors.address ? "error" : ""}
                {...register("address", {
                  required: "Business address is required",
                })}
                disabled={!user_permission?.edit_settings}
                onChange={(e) => handleChange(e.target.value, "address")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.address && errors.address.message}
              </small>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Business Location
              </label>
              <Input
                name="location"
                value={getValues("location")}
                placeholder="FI lender"
                size={"large"}
                className={` text-[14px] ${
                  errors.location ? "error" : ""
                }`}
                status={errors.location ? "error" : ""}
                {...register("location")}
                disabled={!user_permission?.edit_settings}
                onChange={(e) => handleChange(e.target.value, "location")}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.location && errors.location.message}
              </small>
            </div>

            <div className="mb-3 flex flex-col col-span-2">
              <label htmlFor="" className="font-[400] text-[16px]">
                Business description
              </label>
              <TextArea
                rows={4}
                placeholder="Business description"
                {...register("description")}
                disabled={!user_permission?.edit_settings}
                onChange={(e) => handleChange(e.target.value, "description")}
              />
            </div>
          </div>

          <div className="mt-12 mb-10 flex gap-x-3 max-w-lg mx-auto">
            <OutlineCustomButton className={"w-full"} disabled={!formDirty || !user_permission?.edit_settings} onClick={discardEdit}>
              Discard
            </OutlineCustomButton>
            <CustomButton
              type={"submit"}
              disabled={!formDirty || !user_permission?.edit_settings}
              className={"w-full"}
              loading={isLoading}
            >
              Save
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
};
