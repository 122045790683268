import "./sidebar.css";
import { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { CollectionIcon, MenuIcon, RefreshIcon, SettingIcon } from "../../../../utils/svg_icons";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { ROUTES } from "../../../../libs/routes";
import { useProfileData } from "../../../../hooks/useProfileData";
import { logoutFn } from "../../../../logout";
import CustomButton from "../../../common/CustomButton";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IoIosLogOut } from "react-icons/io";


const menus = [
  {path: ROUTES.mandate_status, name: "Mandate Status", icon: RefreshIcon},
  {path: ROUTES.customers, name: "Customers", icon: FaUserFriends},
  {path: ROUTES.transaction, name: "Transaction", icon: CollectionIcon},
  ]

export default function EmandateSidebar({ visible, show, handleClick }) {

  const { data } = useProfileData();


  const screenWidth = useWindowSize().width;

  const activePath = useLocation().pathname;


  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal({});

  const current_page_title = activePath.split('/').pop()?.replace('_', ' ')

  useEffect(() => {
    if (screenWidth >= 900) {
      show(true);
    }
  }, [screenWidth]);



  const confirmLogout = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to proceed to logout",

      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <CustomButton size={"middle"} onClick={ () => {
        logoutFn();
        navigate(ROUTES.login);
      }}  >Confirm</CustomButton>
        </>
      ),

      // okText: "Confirm",
      // cancelText: "Cancel",
      // onOk: () => {
      //   logoutFn("USER");
      //   navigate(ROUTES.login);
      // },
    });
  };


  const getInitials = useCallback((name) => {
    const words = name?.trim()?.split(" ");
  
    // If there is only one word, return the first two letters.
    if (words?.length === 1) {
      return words?.[0]?.substring(0, 2)?.toUpperCase();
    }
  
    // If there are multiple words, return the first letter of each.
    return words
      ?.map((word) => word[0]) // Get the first letter of each word.
      ?.join("")
      ?.toUpperCase();
  }, [])

  return (
    <>
      <div className="emandate_mobile-nav h-screen lg:px-[20%] px-3 md:py-3 py-8 flex justify-between md:shadow-sm shadow">
        <div className="flex items-center">
          {screenWidth < 451 && (
            <button className="mobile-nav-btn" onClick={() => show(!visible)}>
              <MenuIcon clicked={visible} />
            </button>
          )}
          <p className="font-[600] capitalize ps-10">{current_page_title}</p>
        </div>
      </div>
      <nav className={`emandate_sidebar_nav ${visible ? "" : "sidebar"}`}>
        {screenWidth < 900 && (
          <button
            className={`nav_btn`}
            type="button"
            onClick={() => show(!visible)}
          >
            {visible ? (
              <IoIosArrowBack size={"8vh"} />
            ) : (
              <IoIosArrowForward size={"8vh"} />
            )}
          </button>
        )}
        <div className="flex gap-3 items-center pb-3 ps-3">
          <div
            style={{
              height: "46px",
              width: "46px",
              borderRadius: "50%",
              backgroundColor: "#F9D7D68F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#733534",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            {getInitials(data?.company?.name)}
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold my-auto line-clamp-1" style={{ fontSize: "16px" }}>
              
              {data?.company?.name ?? ""}
            </p>
            <p className="font-medium my-auto text-[12px] line-clamp-1">ID: {(data?.company?.company_id)?.slice(0, 5)}...</p>
          </div>
        </div>
        <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
        <div className="flex flex-col mt-3 mb-3 px-[1rem]">
          <Link
            to={ROUTES.dashboard}
            className={`p-3 rounded no-underline text-white flex items-center gap-2 `}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              backgroundColor: activePath===ROUTES.dashboard ? "#733534": "",
              width: "100%",
              fontWeight: 400,
            }}
            onClick={() => {
              window.innerWidth < 768 && show(!visible);
            }}
          >
            <RiDashboardHorizontalFill size={"3vh"} sx={{ color: "#ACA9BB" }} />
            Dashboard
          </Link>
        </div>
        <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
        <div className="flex flex-col mb-3 px-[1rem] gap-y-">
        {
            menus?.map((item, index) => (
             <Link
            to={item?.path}
            className={`p-3 rounded no-underline text-white flex items-center gap-2 `}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              backgroundColor: activePath===item?.path ? "#733534" : "",
              width: "100%",
              fontWeight: 400,
            }}
            onClick={() => {
              window.innerWidth < 768 && show(!visible);
            }}
          >
            <item.icon size={"3vh"} style={{ color: "#ACA9BB" }} />
            {item?.name}
          </Link>
            ))
        }
       
        </div>
        <hr className="border-0 h-[1px] bg-[#7D83984D] my-3" />
        <div className="flex flex-col mt-3 mb-3 px-[1rem]">
          <Link
            to={ROUTES.settings}
            className={`p-3 rounded no-underline text-white flex items-center gap-2 `}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              backgroundColor: activePath?.includes("settings") ? "#733534" : "",
              width: "100%",
              fontWeight: 400,
            }}
            onClick={() => {
              window.innerWidth < 768 && show(!visible);
            }}
          >
            <SettingIcon size={"3vh"} sx={{ color: "#ACA9BB" }} />
            Setting
          </Link>
        </div>
        <div className="flex flex-col mt-3 mb-3 px-[1rem]">
          <div
            className={`p-3 rounded no-underline text-white flex items-center gap-2 cursor-pointer`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              width: "100%",
              fontWeight: 400,
            }}
            onClick={confirmLogout}
          >
            <IoIosLogOut size={"3vh"} sx={{ color: "#ACA9BB" }} />
            Logout
          </div>
        </div>
      </nav>


      {contextHolder}
    </>
  );
}
