import moment from 'moment'
import React from 'react'

const TimelineCard = ({index, timeline}) => {
  return (
    <>
         <div className={`md:fle grid md:grid-cols-6 grid-cols-1 gap-x-10 gap-y-3 ${index!==0 && "border-t border-black mt-4 pt-6"}`}>
            <div>
              <p className="font-[500] text-[14px]">{moment(timeline?.date).format("ll")}</p>
            </div>
            <div className="flex flex-col gap-y-3 col-span-5">
           {
           timeline?.data?.map((item, index)=>{
                return(
                  <div className="fle grid grid-cols-5 sm:justify-between md:gap-x-10 gap-x-5" key={index+"____child"+item?.id}>
                  <div className="flex gap-x-3 my-auto items-center col-span-3">
                    <p className="font-[400] text-[14px] text-nowrap">
                      {moment(item?.createdAt).format("LT")}
                    </p>
                    <div className="relative border border-black bg-black h-[1px] w-20">
                      <p className="absolute w-2 h-2 bg-black rounded-full -right-1 -top-1"></p>
                    </div>
  
                    <p className="font-[400] text-[14px] hyphens-auto overflow-hidden break-words">{item?.action}</p>
                  </div>
                  <p className="font-[400] text-[14px] hyphens-auto overflow-hidden break-words col-span-2">{item?.user?.first_name} {item?.user?.last_name}</p>
                </div>
                )
            })
           }
              
            </div>
          </div>
    </>
  )
}

export default TimelineCard