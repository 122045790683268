import { Radio } from "antd";
import { useEffect, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { useSendSchedule } from "../../../../api/mutation";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import CustomButton from "../../../common/CustomButton";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/form_inputs/Input";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import loan_schedule_example from "../../../../assets/loan_schedule_example.csv"

export default function LoanMandateInformation() {
  const {
    openDrawer,
    data: { values },
  } = useDrawer();

  const { contextHolder, showMessage } = useCustomMessage();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
    watch,
    control
  } = useForm({
    defaultValues: {
      sc_file: null,
    },
  });

  const [fileSelected, setFileSelected] = useState(false);

  const [tenorType, setTenorType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useSendSchedule();

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }


    formData?.append("file", data["sc_file"])

    // for (const key in data) {
    //   if (key === "sc_file") {
    //     formData.append("file", data[key]); // Append sc_file with key 'file'
    //   } else {
    //     formData.append(key, data[key]);
    //   }
    // }

    formData?.append("preview", "true");

    // for (let [key, value] of formData.entries()) {
    //       console.log({key, value, type: typeof(value)});
    //     }

    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    // console.log(values, data);

    setIsLoading(true);
    mutation.mutate(formData, {
      onSuccess: (res) => {
        console.log(res?.data?.data);
        openDrawer(
          "MANDATE_SUMMARY",
          { formData, mandate: res?.data?.data },
          "MANDATE_INFO"
        );
        reset();
      },
    });
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error =
        mutation?.error?.response?.data?.message ?? mutation?.message;

      showMessage("error", error);
    } else if (mutation.isSuccess) {
      setIsLoading(false);
      const res = mutation?.data?.data?.data;
    }
  }, [mutation.status]);

  const onChange = (e) => {
    setTenorType(e.target.value);
    // formik.setFieldValue("payment_type", e.target.value);

    setValue("payment_type", e.target.value);
    trigger("payment_type");
  };

  const options = [
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const onChangeNumber = (e, fieldName) => {
    if (fieldName === "sc_file") {
      // formik.setFieldValue("sc_file", e.target.files[0])
      const value = e.target.files[0];

      console.log(value)

      setFileSelected(value);

      setValue(fieldName, value, { shouldValidate: true });
    } else {
      const value = e.target.value;

      setValue(fieldName, value);
      // formik.setFieldValue(e.target.name, e.target.value)
    }
    trigger(fieldName); // Trigger validation after setting value
  };

  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg border border-[#D9D9D996] bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Loan Information</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Total Repayable Amount
              </label>
              <Input
                type="number"
                value={getValues("total_amount")}
                name="total_amount"
                {...register("total_amount", {
                  required: "Total amount is required",
                })}
                onChange={(e) => onChangeNumber(e, "total_amount")}
                placeholder="Enter Loan Amount"
                size={"large"}
                className={` text-[14px] placeholder:text-[#033F4B] ${
                  errors.total_amount ? "error" : ""
                }`}
                status={errors.total_amount ? "error" : ""}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.total_amount && errors.total_amount.message}
              </small>
            </div>
          
            <div className="mb-3">
              <label htmlFor="" className="font-[400] text-[16px]">
                Duration of repayment
              </label>
              <Input
                type="number"
                value={getValues("tenor")}
                name="tenor"
                {...register("tenor", {
                  required: "Total amount is required",
                })}
                onChange={(e) => onChangeNumber(e, "tenor")}
                placeholder="Enter loan tenor in number e.g 1"
                size={"large"}
                className={` text-[14px] placeholder:text-[#033F4B] ${
                  errors.tenor ? "error" : ""
                }`}
                status={errors.tenor ? "error" : ""}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.tenor && errors.tenor.message}
              </small>
              <Radio.Group
                onChange={onChange}
                value={tenorType}
                className="mt-2"
              >
                <Radio value={"weekly"}>weekly</Radio>
                <Radio value={"monthly"}>monthly</Radio>
                <Radio value={"yearly"}>yearly</Radio>
              </Radio.Group>
            </div> */}
            <div className="mb-2 gap-x-2 items-center">
              <label htmlFor="" className="font-[400] text-[16px]">
                Loan Schedule
              </label>
              <div className="flex gap-x-4 justify-between border rounded-[8px] items-center  ps-3">
                <span className="font-[400] text-[14px] text-[#033F4B]">
                  {fileSelected?.name ??
                    "Upload loan schedule here in csv format"}
                </span>

                <label
                  htmlFor="schedule_file"
                  className=" rounded-s-[0.8rem] border px-8 py-3 "
                >
                  Select file
                  {/* <Controller
            name="sc_file"
            control={control}
            render={({ field }) => ( */}
              <input
                type="file"
                name="sc_file"
                id="schedule_file"
                // {...register("sc_file", {
                //   required: "Loan schedule file is required",
                // })}
                // {...field}
                onChange={(e) => onChangeNumber(e, "sc_file")}
                className="hidden"
                accept=".csv,text/csv"
              />

            {/* )}
            rules={{required: "Loan schedule fle is required"}}
            /> */}
                </label>
              </div>
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.sc_file && errors.sc_file.message}
              </small>
              {/* {
                      fileSelected&& <span className="italic text-[#790707] text-[16px] text-center font-[400]">
                    View and download csv template
                    </span>
                    } */}
              <p className="text-[14px] font-[400] text-[#033F4B]">
                <a href={loan_schedule_example} download={'loan_schedule_example.csv'} className="font-[700] text-[15px] cursor-pointer">Download{" "}</a>
              
                loan schedule template{" "}
              </p>
            </div>
            <div className="mt-4 flex justify-center">
              <CustomButton
                type={"submit"}
                className="px-8 w-full"
                loading={isLoading}
                disabled={!getValues('sc_file')}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
