import { Button, ConfigProvider } from "antd";

function OutlineCustomButton({loading, children, type, size, className, ...rest}) {
    return ( 
        <>
        <ConfigProvider theme={{
            components: {
                Button: {
                    defaultHoverBorderColor: "#733534",
                    defaultHoverColor: "#733534",
                    defaultColor: "#733534",
                    defaultBorderColor: "#733534",
                }
            }
        }}>
            <Button
            htmlType={type ?? 'button'}
                    {...rest}
                    size={size ?? "large"}
                    loading={loading}
                    className={`rounded-[8px] text-[16px] ${size? "": "h-[42px]"} ${className}`}
                    >
                    {children}
                  </Button>
        </ConfigProvider>
        </>
     );
}

export default OutlineCustomButton;