import { Input, Select, Spin, Result } from "antd";
import React, { useMemo } from "react";
import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import TransactionsTable from "../../../components/common/table/transaction_table/TransactionsTable";
import { IoSearchOutline } from "react-icons/io5";
import { useUserPermission } from "../../../hooks/useUserPermission";
import { exportExcel } from "../../../utils/exportExcel";
import { useTransactions } from "../../../api/getHooks";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";

const { Search } = Input;

const response = {
  message: "Pending users fetched",
  data: [
    {
      bioData: {},
      contact: {},
      employmentDetails: {},
      location: {},
      id: "93d8873b-13b8-440a-bd40-31c6827bef07",
      first_name: "Ade",
      last_name: "Akin",
      email: "denirfreeman@gmail.com",
      phone: "09060002152",
      email_verified: false,
      is_admin: true,
      profile_picture: null,
      token: null,
      type: null,
      status: false,
      kyc_status: false,
      phone_verified: false,
      mono_id: null,
      allowed_plan: null,
      selected_subType: null,
      role: "admin",
      kyc_stepper: 0,
      deactivate: false,
      is_delete: false,
      createdAt: "2024-02-17T22:06:53.000Z",
      updatedAt: "2024-02-17T22:06:53.000Z",
    },
    {
      bioData: {
        gender: "Male",
        maritalStatus: "Single",
        dob: "20/10/1994",
        bvn: "223454456655",
      },
      contact: {
        address: "No 4 omolara close gbagada",
        city: "Lagos",
        state: "Lagos",
      },
      employmentDetails: {
        occupation: "Developer",
        workEmail: "taiwo@get440.com",
        salary: "200000",
        employer: "Femo",
        employeraddress: "VI Lagos",
      },
      location: {},
      id: "ae164050-d4dd-4cb7-895d-33f6308f2bae",
      first_name: "Taiwo",
      last_name: "Enoch",
      email: "enochtaiwotimothy@gmail.com",
      phone: "08102637956",
      email_verified: false,
      is_admin: false,
      profile_picture:
        "https://res.cloudinary.com/dcyr2rnly/image/upload/v1706452583/ryycnlmlcjvylz4aghpc.jpg",
      token: null,
      type: null,
      status: true,
      kyc_status: true,
      phone_verified: true,
      mono_id: null,
      allowed_plan: "a339e74e-b3bc-48ed-8f9d-ae848bc542df",
      selected_subType: null,
      role: "user",
      kyc_stepper: 7,
      deactivate: false,
      is_delete: false,
      createdAt: "2024-02-15T11:52:08.000Z",
      updatedAt: "2024-02-17T23:38:01.000Z",
    },
  ],
};

export const Transactions = () => {
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useTransactions({
    page: 1,
  });

  const transactionData = useMemo(() => {
    return data?.data;
  }, [data]);

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const handleOpenDrawer = (data) => {
    // openDrawer('CUSTOMER_SCHEDULE', {user : data})
    // updateDrawerTitle(`${data?.first_name} ${data?.last_name}`)
  };

  // Set the locale to start the week on Monday
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    queryClient.invalidateQueries({
      queryKey:[
      "get_transaction",
      {
        start_date: startDate,
        end_date: endDate,
      },
    ]});

    // console.log(startDate, endDate);
  };

  const exportAs = (value) => {
    exportExcel({
      excelData: response?.data,
      fileName: `all mandates`,
    });
  };

  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black">Transactions</h3>
        <div className="flex justify-end mb-3">
          <div className="flex gap-x-2">
            <Select
              size="small"
              defaultValue="all_time"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "all_time", label: "All time" },
                { value: "today", label: "Today" },
                { value: "week", label: "This week" },
                { value: "month", label: "This month" },
                { value: "year", label: "This year" },
              ]}
            />
          </div>
        </div>
        <div className="flex gap-x-3 mt-6 mb-3 justify-between">
          <Input
            size="large"
            placeholder="Search reference"
            style={{
              width: 250,
            }}
            prefix={
              <IoSearchOutline
                size={"16px"}
                style={{
                  color: "rgba(0,0,0,.25)",
                }}
              />
            }
          />
          <div className="flex gap-x-2">
            <Select
              size="large"
              placeholder="Filter"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "Direct debit",
                  label: "direct debit",
                },
                {
                  value: "alternative payment",
                  label: "alternative payment",
                },
              ]}
            />
            <Select
              size="large"
              placeholder="Export as"
              style={{
                width: 120,
              }}
              onChange={exportAs}
              options={[
                {
                  value: "excel",
                  label: "Export as excel",
                },
              ]}
            />
          </div>
        </div>

        {
          isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) :
          permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : user_permission?.view_transactions ? (
            <TransactionsTable
              rows={transactionData?.data}
              handleOpen={handleOpenDrawer}
            />
          ) : (
            <div className="text-center my-10">
              <i className="text-lg">
                You are not permitted to view transactions
              </i>
            </div>
          )
        }
      </main>
    </>
  );
};
