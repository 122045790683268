import { ConfigProvider, Radio } from "antd";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const options = [
  {
    label: "Profile",
    value: "profile",
  },
  {
    label: "Business info",
    value: "business info",
  },
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Permissions",
    value: "permissions",
  },
  {
    label: "Team",
    value: "team",
  },
  {
    label: "Timeline",
    value: "timeline",
  },
];

export const SettingTabs = () => {

  const [ searchParams] = useSearchParams();

  const tab = searchParams.get("tab")

  const navigate = useNavigate({});

  //
  const handleTabChange = (e) => {
    const tab = e.target.value;

    const params = new URLSearchParams(window.location.search);
    params.set('tab', tab); // Set your search parameter here
    navigate({ search: params.toString() });


  };

  return (
    <>
      <ConfigProvider
        theme={{
            token: {
                colorPrimary: "#733534",
                // controlHeight: "44px",
            },
          components: {
            Radio: {
              /* here is your component tokens */
              buttonSolidCheckedActiveBg: "#733534",
              buttonSolidCheckedBg: "#733534",
              buttonSolidCheckedHoverBg: "#733534",
              buttonSolidCheckedHoverColor: "#733534",
            },
          },
        }}

      >
        <Radio.Group
          options={options}
          onChange={handleTabChange}
          value={tab}
          style={{
            marginBottom: 8,
          }}
          size="large"
          optionType="button"
          buttonStyle="solid"
          className="rounded-[8px] items-center"
         />
      </ConfigProvider>
    </>
  );
};
