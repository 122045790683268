import React from 'react'
import { Input as AntInput, ConfigProvider } from "antd"

const PasswordInput = ({className, ...rest}) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Input: {
                    hoverBorderColor: "#733534",
                    activeBorderColor: "#733534",
                    activeShadow: "0",
                }
            }
        }}>

         <AntInput.Password
               {...rest}
                className={`h-[42px] text-[14px] ${className}`}
              />

        </ConfigProvider>
    </>
  )
}


export default PasswordInput;