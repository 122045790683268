import { create } from "zustand";


// expected page title: LOAN_DETAIL, SINGLE_LOAN_DETAIL, DEBIT_ACCOUNT, DEBIT_RESULT

export const useLoanDrawer = create((set)=>{
    return{
        isOpen: false,
        pageTitle: "LOAN_DETAIL",
        data: {},

        openDrawer: (title, data)=>set((state)=>({isOpen: true, data: {...data}, pageTitle: title})),
        updateData: (newData)=>set((state) => ({
            data: { ...state.data, ...newData }
        })),
        updateTitle: (title)=>set((state)=>({pageTitle: title}) ),

        closeDrawer:()=>set({isOpen: false})
    }
})