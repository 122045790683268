// Function to group data by date
export const groupByDateArray = (data) => {
    // Use an object to accumulate data grouped by date
    const grouped = data?.reduce((acc, item) => {
      // Extract the date part from the createdAt timestamp
      const date = new Date(item.createdAt)?.toISOString()?.split('T')[0];
  
      // If the date does not exist in the accumulator, create a new array for it
      if (!acc[date]) {
        acc[date] = [];
      }
  
      // Add the item to the respective date's array
      acc[date].push(item);
  
      return acc;
    }, {}); // Initialize the accumulator as an empty object
  
    // Convert the object to an array of { date, data } objects
    return Object?.keys(grouped)?.map((date) => ({
      date,
      data: grouped[date],
    }));
  };
  