import React, { useState } from "react";
import PasswordInput from "../../../common/form_inputs/PasswordInput";
import { useForm } from "react-hook-form";
import CustomButton from "../../../common/CustomButton";
import { useChangePsw } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useNavigate } from "react-router-dom";
import { logoutFn } from "../../../../logout";
import { ROUTES } from "../../../../libs/routes";

const ChangePasswordForm = ({closeModal}) => {


  const mutation = useChangePsw();

  const navigate = useNavigate()




  const { showMessage, contextHolder } = useCustomMessage();

  

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
    watch
  } = useForm({
    defaultValues: {
        old_password: "",
        new_password: "",
        confirm_password: ""
    },
  });


  const onsubmit = (data) => {
    const json = {
        old_password: data.old_password,
        new_password: data.new_password,
        confirm_password: data.confirm_password
    };

    setIsLoading(true);

    mutation.mutate(json, {
      onError: (error) => {
        const err = error?.response?.data?.message ?? error?.message;

        showMessage("error", err);
      },
      onSuccess: (data) => {
        const res = data?.data;

        reset();

        showMessage("success", res?.message);
        closeModal();

        logoutFn();
        navigate(ROUTES.login);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };



   // Watch the value of the "password" field
   const new_password = watch("new_password");

  const handleChange = (value, fieldName) => {
    setValue(fieldName, value);
    trigger(fieldName);
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <p className="my-5 font-[500] text-[16px] text-black capitalize">
          Change Password
        </p>
        <form action="" onSubmit={handleSubmit(onsubmit)}>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px] text-white">
              Old Password
            </label>
            <PasswordInput
              name="old_password"
              value={getValues("old_password")}
              type="old_password"
              placeholder="Enter your old password"
              size={"large"}
              className={`text-[16px] ${errors.old_password ? "error" : ""}`}
              status={errors.old_password ? "error" : ""}
              {...register("old_password", {
                required: "old password is required",
              })}
              onChange={(e) => handleChange(e.target.value, "old_password")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.old_password && errors.old_password.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px] text-white">
              New Password
            </label>
            <PasswordInput
              name="new_password"
              value={getValues("new_password")}
              type="new_password"
              placeholder="Enter your New password"
              size={"large"}
              className={`text-[16px] ${errors.new_password ? "error" : ""}`}
              status={errors.new_password ? "error" : ""}
              {...register("new_password", {
                required: "New password is required",
              })}
              onChange={(e) => handleChange(e.target.value, "new_password")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.new_password && errors.new_password.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="font-[400] text-[16px] text-white">
              Confirm Password
            </label>
            <PasswordInput
              name="confirm_password"
              value={getValues("confirm_password")}
              type="confirm_password"
              placeholder="Confirm password"
              size={"large"}
              className={`text-[16px] ${errors.confirm_password ? "error" : ""}`}
              status={errors.confirm_password ? "error" : ""}
              {...register("confirm_password", {
                required: "Confirm password is required",
                validate: (value) =>
              value === new_password || "Passwords do not match"
              })}
              onChange={(e) => handleChange(e.target.value, "confirm_password")}
            />
            <small style={{ fontSize: "13px" }} className="text-red-500">
              {errors.confirm_password && errors.confirm_password.message}
            </small>
          </div>
          <div className="mt-10 mb-3">
            <CustomButton
              type={"submit"}
              className={"w-full"}
              loading={isLoading}
            >
              Change
            </CustomButton>
          </div>
        </form>
      </main>

      {contextHolder}
    </>
  );
};

export default ChangePasswordForm;
