import React, { useMemo } from "react";
import { SettingTabs } from "../../../components/specific/settings/setting_tabs/SettingTabs";
import { useSearchParams } from "react-router-dom";
import { ProfileView } from "../../../components/specific/settings/profile/ProfileView";
import { BusinessInfoView } from "../../../components/specific/settings/business_info/BusinessInfoView";
import { PreferencesView } from "../../../components/specific/settings/preferences/PreferencesView";
import { AccountView } from "../../../components/specific/settings/accounts/AccountView";
import { PermissionView } from "../../../components/specific/settings/permissions/PermissionView";
import { TeamsView } from "../../../components/specific/settings/teams/TeamsView";
import { TimelineView } from "../../../components/specific/settings/timeline/TimelineView";


const tabs = [
  {
    component: ProfileView,
    value: "",
  },
  {
    component: ProfileView,
    value: "profile",
  },
  {
    component: BusinessInfoView,
    value: "business info",
  },
  {
    component: PreferencesView,
    value: "preferences",
  },
  {
    component: AccountView,
    value: "accounts",
  },
  {
    component: PermissionView,
    value: "permissions",
  },
  {
    component: TeamsView,
    value: "team",
  },
  {
    component: TimelineView,
    value: "timeline",
  },
];

export const Settings = () => {

  const [ searchParams] = useSearchParams();

  const currentTab = searchParams.get("tab")


  const renderView = tabs.find(tab => tab.value===currentTab) ?? tabs[0]



  return (
    <>
      <main className="mt-3">
        <h3 className="font-[500] text-[18px] my-2 text-black capitalize">{currentTab}</h3>
        <SettingTabs />



    <section className="mt-10">
        <renderView.component/>




    </section>


      </main>
    </>
  );
};
