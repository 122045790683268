import { create } from "zustand";


export const useDrawer = create((set)=>{
    return{
        isOpen: false,
        pageTitle: "",
        data: {},
        drawerTitle: "",
        backTo: null,

        openDrawer: (title, newData, backTo)=>{
        set((state)=>({isOpen: true, data: {...state.data, ...newData}, backTo: backTo, pageTitle: title}))
        },
        updateData: (newData)=>set((state) => ({
            data: { ...state.data, ...newData }
        })),
        updateTitle: (title)=>set((state)=>({pageTitle: title}) ),
        updateDrawerTitle: (title)=>set((state)=>({drawerTitle: title}) ),

        closeDrawer:()=>set({isOpen: false})
    }
})