import { ConfigProvider } from 'antd'
import React from 'react'

function ButtonConfig({children}) {
  return (
<ConfigProvider theme={{
            components: {
                Button: {
                    defaultHoverBorderColor: "#733534",
                    defaultHoverBg: "#733534",
                    defaultBg: "#733534",
                    defaultHoverColor: "#fff",
                    defaultColor: "#fff",
                    defaultBorderColor: "#733534",
                    defaultActiveBorderColor: "#733534",
                    defaultActiveBg: "#733534",
                    defaultActiveColor: "#FFF",
                    defaultShadow: "0"
                }
            }
        }}>{children} </ConfigProvider>
  )
}

export default ButtonConfig