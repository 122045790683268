import { Button, Checkbox, Modal } from "antd";
import { useState, useEffect, useMemo } from "react";
import {
  useCheckBalance,
  useDebitAccount,
} from "../../../../api/admin_mutation";
import {
  useViewSchedule
} from "../../../../api/getHooks";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import moment from "moment";
import { formatNumberOnly } from "../../../../utils/formatNumber";
import CustomButton from "../../../common/CustomButton";
import { PiWarningCircle } from "react-icons/pi";
import { Input } from "../../../common/form_inputs/Input";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { useProfileData } from "../../../../hooks/useProfileData";
import { useQueryClient } from "@tanstack/react-query";

export default function DebitAccount() {
  const [selectedAcc, setSelectedAcc] = useState({ check: null, id: null });
  const [debitDetail, setDebitDetail] = useState({ status: null, id: null });

  const queryClient = useQueryClient()

  const {
    openDrawer,
    data: { single_schedule, accounts, mandate },
  } = useDrawer();


  

  const {data, isLoading} = useViewSchedule(single_schedule?.id);

  const paymentDetail = data?.data?.data

  const [modal, contextHolder] = Modal.useModal();

  const [amount, setAmount] = useState("");

  const { data: profile } = useProfileData();
  const [accountBalance, setAccountBalance] = useState(0);

  const [debitOtherAmount, setDebitOtherAmount] = useState(false);

  const [totalAmountToDebit, setTotalAmountToDebit] = useState(0);

  const [debitAccResponse, setDebitAccResponse] = useState({
    msg: "",
    status: null,
    show: false,
  });

  const [validateMsg, setValidateMsg] = useState("");

  const mutation = useDebitAccount();

  const checkBalance = useCheckBalance();

  const [amountPaid, setAmountPaid] = useState(single_schedule?.amount_paid);

  const { showMessage, contextHolder: contextHolder2 } = useCustomMessage();

  const amountDue =
    parseFloat(single_schedule?.total_amount_due) - parseFloat(amountPaid);

  const end_date = single_schedule?.end_date; // The due date of the payment
  const start_date = single_schedule?.start_date; // The due date of the payment
  const grace_period = mandate?.grace_period || 0; // Number of weeks for grace period
  const grace_period_type = mandate?.grace_period_type;

  // Calculate the number of days since the due date
  const daysSinceEndDate = moment().diff(moment(end_date), "days");
  const isDue = moment().diff(moment(start_date), "days");

  // Calculate the end of the grace period
  const endOfGracePeriod = moment(end_date).add(
    grace_period,
    grace_period_type
  );

  // console.log(endOfGracePeriod)

  // Determine if today is within the grace period
  const isWithinGracePeriod = moment().isBefore(endOfGracePeriod);

  const daysBeyondGracePeriod = moment().diff(endOfGracePeriod, "days");

  const defaultDays = moment().diff(moment(end_date), "days");

  const penalChargePerDay = useMemo(() => {
    if (daysSinceEndDate > 0) {
      if (isWithinGracePeriod) {
        // Within the grace period, apply the regular penal charge
        return (
          (parseFloat(mandate?.penal_charges) / 100) * parseFloat(amountDue)
        ); //daysSinceEndDate * mandate?.penal_charges;
      } else {
        // Beyond the grace period, apply the overdue penal charge

        return (
          (parseFloat(mandate?.over_grace_penal_charges ?? 0.4) / 100) *
          parseFloat(amountDue)
        ); //(grace_period * penal_charge) + (daysBeyondGracePeriod * overdue_penal_charge);
      }
    } else {
      // No penalty if the payment is not yet overdue
      return 0;
    }
  }, []);




  const accruedPenalCharge =
    daysSinceEndDate <= 0
      ? 0
      : parseFloat(penalChargePerDay) * parseFloat(daysSinceEndDate);

  // const repayableAmount = amountDue + accruedPenalCharge; //=====================(calculated by me)==================
  const repayableAmount = paymentDetail?.repayable_amount ?? 0;

  const transactionFee = useMemo(() => {
    if (!profile?.company?.cost_on_customer) {  
      return 0;
    } else if (debitOtherAmount) {
      return amount * (1 / 100) > 1000 ? 1000 : (1 / 100) * amount;
    } else {
      // return repayableAmount * (1 / 100) > 1000
      //   ? 1000
      //   : repayableAmount * (1 / 100);
      return paymentDetail?.transaction_fee
    }
  }, [debitOtherAmount, amount, paymentDetail]); //========================(calculated by my)=====================================
  
  // const transactionFee = paymentDetail?.transaction_fee ?? 0;


  useEffect(() => {
    if (debitOtherAmount && amount <= 0) {
      setTotalAmountToDebit(0);
    } else if (debitOtherAmount) {
      setTotalAmountToDebit(parseFloat(amount) + parseFloat(transactionFee));
    } else {
      setTotalAmountToDebit(
        parseFloat(repayableAmount ?? 0) + parseFloat(transactionFee ?? 0)
      );
    }
  }, [debitOtherAmount, amount, paymentDetail]);

  const handleSelectAcc = (e, id) => {
    setSelectedAcc({ check: e.target.checked, id });
  };

  const handleDebitMoney = (amountDue) => {
    //function to debit moeny will be here
    openDrawer("DEBIT_RESULT", { amountDue, status: "success" }, "DEBIT_ACCOUNT");
  };

  const startDebit = (id) => {
    setDebitDetail({ id, status: true });
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const checkBalanceFn = (accountID) => {
    checkBalance.mutate(accountID, {
      onError: (err) => {
        const errorMsg = err?.response?.data?.message ?? err?.message;

        showMessage("error", errorMsg);
      },
      onSuccess: (data) => {
        const res = data?.data?.data;
        setAccountBalance(res?.account_balance);
        queryClient.invalidateQueries(['get_profile'])
      },
    });
  };

  const [open, setOpen] = useState(false);
  const showDebitConfirm = (account_id) => {
    setOpen(true);
  };
  const handleOk = (account_id) => {
    console.log(account_id);
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setOpen(false);
    //   setConfirmLoading(false);
    // }, 2000);

    debitAccount(account_id);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const debitAccount = (account_id) => {
    const totalDefaultRepayable = transactionFee + repayableAmount;

    const json = {
      schedule_id: single_schedule?.id,
      amount: totalAmountToDebit, //debitOtherAmount ? amount: totalDefaultRepayable,
      account_id: account_id,
    };

    mutation.mutate(json, {
      onSuccess: (data) => {
        const res = data?.data;
        setDebitAccResponse({ msg: res?.message, status: true, show: true });
        setAmount("");
        setDebitOtherAmount(false);
        setAmountPaid(parseFloat(amountPaid) - parseFloat(amount));
        setOpen(false);

        queryClient.invalidateQueries(["view_schedule"])
        queryClient.invalidateQueries(['get_profile'])
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.message ?? error?.message;

        setDebitAccResponse({ msg: errMsg, status: false, show: true });
        setOpen(false);
      },
    });

    // console.log(json)

    //     modal.confirm({
    //           title: 'Confirm',
    //           // icon: <ExclamationCircleOutlined />,
    //           content: `Are you sure to debit this amount`,
    //           okText: 'Debit',
    //           cancelText: 'Cancel',
    //           onOk:()=>{
    //             console.log("confirmede")

    //           }
    //         });
  };

  // useEffect(() => {
  //   if (mutation.isPending) {
  //     setIsLoading(true);
  //   } else if (mutation.isError) {
  //     setIsLoading(false);
  //     const error = mutation?.error?.response?.data;

  //     console.log(error);
  //     openDrawer("DEBIT_RESULT", { status: "error" }, "DEBIT_ACCOUNT");
  //   } else if (mutation.isSuccess) {
  //     setIsLoading(false);
  //     const res = mutation?.data?.data?.data;

  //     openDrawer("DEBIT_RESULT", { amountDue, status: "success" }, "DEBIT_ACCOUNT");
  //   }
  // }, [mutation.status]);

  useEffect(() => {
    if (amount > repayableAmount) {
      setValidateMsg("Amount can not exceed the repayable amount");
    } else {
      setValidateMsg("");
    }
  }, [amount]);


  return (
    <>
      <main className="w-full max-w-2xl mx-auto">
        <div className="flex flex-col gap-y-1">
          <div className="flex justify-between">
            <p className="font-[500] text-[16px]">
              {mandate?.first_name} {mandate?.last_name}
            </p>
            <p className="font-[500] text-[16px]">
              Customer ID:
              {mandate?.userId}
            </p>
          </div>
          <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1 mt-2">
            <p className="font-[500] text-[14px]">
              Amount Due{" "}
              <span className="font-[300]">
                {/* NGN {formatNumberOnly(amountDue)} */}
                NGN {formatNumberOnly((paymentDetail?.amount_due)?.toFixed(2))}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Schedule Date{" "}
              <span className="font-[300]">
                {moment(paymentDetail?.schedule_date).format("ll")}
              </span>
            </p>
            {daysSinceEndDate >= 1 ? (
              <p className="font-[500] text-[14px]">
                Defaulted Days:{" "}
                {/* <span className="font-[300]">{daysSinceEndDate}days</span> */}
                <span className="font-[300]">{paymentDetail?.default_days}days</span>
              </p>
            ) : null}
            <p className="font-[500] text-[14px]">
              Accrued Penal charges:{" "}
              <span className="font-[300]">
                {/* NGN {formatNumberOnly(accruedPenalCharge)} */}
                NGN {formatNumberOnly((paymentDetail?.accrued_charges)?.toFixed(2))}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Penal charge per day:{" "}
              <span className="font-[300]">
                {/* NGN {formatNumberOnly(penalChargePerDay)} */}
                NGN {formatNumberOnly((paymentDetail?.penal_charges_per_day)?.toFixed(2))}
              </span>
            </p>
            {profile?.company?.cost_on_customer ? (
              <p className="font-[500] text-[14px]">
                Transaction fee:{" "}
                <span className="font-[300]">{(paymentDetail?.transaction_fee)?.toFixed(2)}<small>(1% of Repayable amount)</small></span>
              </p>
            ) : null}
            <p className="font-[500] text-[14px]">
              Total Repayable Amount:{" "}
              <span className="font-[300]">
                {/* NGN {formatNumberOnly(repayableAmount)} */}
                NGN {formatNumberOnly((paymentDetail?.repayable_amount)?.toFixed(2))}
              </span>
            </p>
          </div>
        </div>
        <p className="text-center font-[300] mt-5">
          Debit <span className="font-[500]">{(totalAmountToDebit).toFixed(2) ?? 0}</span>{" "}
          from any of the account below

        </p>

        <section className="mt-16 flex flex-col gap-y-14">
          {accounts?.length ? (
            <>
              {accounts
                ?.filter((acc) => acc?.status === "active")
                ?.map((item, index) => {
                  return (
                    <div className="flex gap-x-5" key={index}>
                      <div>
                        <Checkbox
                          onChange={(e) => handleSelectAcc(e, index)}
                          checked={
                            selectedAcc.check && selectedAcc.id === index
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-y-8 w-full">
                        <div className="flex md:flex-row flex-col justify-between gap-3 md:gap-14 md:grid md:grid-cols-2">
                          <div className="flex justify-between items-center border-b w-full border-b-[#CFCDCC]">
                            <div className="flex gap-x-2 items-center">
                              {/* <img
                              src={
                                "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
                              }
                              alt={"bank_url"}
                              width={50}
                            /> */}
                              <p className="text-[14px] font-[400]">
                                {item?.initiate_mandate_data?.bank}
                              </p>
                            </div>
                            <p className="font-[700] text-[14px]">
                              {item?.initiate_mandate_data?.account_number}
                            </p>{" "}
                          </div>
                          <div
                            className={`${
                              selectedAcc.check && selectedAcc.id === index
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            <p
                              className={`flex gap-x-1 font-[400] text-[14px] ${
                                debitAccResponse?.status
                                  ? "text-green-500"
                                  : "text-red-500"
                              }
                                        ${!debitAccResponse?.show && "hidden"}`}
                            >
                              <PiWarningCircle
                                color={
                                  debitAccResponse?.status
                                    ? "text-green-500"
                                    : "text-red-500"
                                }
                                size={20}
                              />

                              <i>
                                {
                                  debitAccResponse?.msg
                                  // "Cannot debit due to low balance"
                                }
                              </i>
                            </p>
                          </div>
                        </div>
                        <div
                          className={`${
                            selectedAcc.check && selectedAcc.id === index
                              ? "flex md:flex-row flex-col justify-between gap-3 md:gap-14 md:grid md:grid-cols-2 transition-all "
                              : "hidden"
                          }`}
                        >
                          <div className="ps-3 col-span-2">
                            {/* <div>
                              <div className="flex gap-x-5 items-center">
                                <CustomButton
                                  size={"small"}
                                  className={"text-[12px] !rouned-[3px]"}
                                  loading={checkBalance.isPending}
                                  onClick={() => checkBalanceFn(item?.id)}
                                >
                                  Check balance
                                </CustomButton>
                                <p className="font-[500] text-[12px] text-[#033F4B]">
                                  Balance:{" "}
                                  <span className="font-[300]">
                                    NGN {accountBalance}
                                  </span>
                                </p>
                              </div>
                              <p className="font-[400] text-[12px] mt-2">
                                You have <span className="font-[700]">{profile?.company?.check_balance}</span>{" "}
                                clicks to check balance{" "}
                              </p>
                            </div> */}

                            <div className={`mt-3`}>
                              <Checkbox
                                className="font-[400] text-[13px]"
                                checked={debitOtherAmount}
                                onChange={(e) =>
                                  setDebitOtherAmount(e.target.checked)
                                }
                              >
                                Debit other amount if the balance does not match
                                repayment{" "}
                              </Checkbox>
                              {debitOtherAmount && (
                                <div className="mt-3">
                                  <Input
                                    type="number"
                                    placeholder="Enter amount"
                                    className="mt-2 md:w-[50%] w-full"
                                    size="large"
                                    onChange={handleAmountChange}
                                    status={validateMsg ? "error" : ""}
                                  />

                                  <p>
                                    <small className="text-red-500">
                                      {validateMsg}
                                    </small>
                                  </p>
                                </div>
                              )}
                              <CustomButton
                                // loading={mutation?.isPending}
                                className={`md:w-[50%] w-full mt-3`}
                                onClick={() => showDebitConfirm(item?.id)}
                                disabled={
                                  isDue <= 0 || (debitOtherAmount && amount < 200) ||
                                  validateMsg
                                }
                              >
                                Debit {`NGN ${totalAmountToDebit?.toFixed(2)}`}
                              </CustomButton>

                              <Modal
                                title="Debit Confirmation"
                                open={open}
                                onOk={() => handleOk(item?.id)}
                                confirmLoading={mutation.isPending}
                                onCancel={handleCancel}
                              >
                                <p>Do you want to continue this transantion</p>
                              </Modal>
                            </div>
                          </div>
                          <div className="">
                            {/* {item?.status === "active" ? (
                              <Button
                                size="middle"
                                loading={isLoading}
                                className={`rounded-[8px] bg-[#733534] text-[#FFF] hover:!bg-[#733534] hover:!text-[#FFF] capitalize`}
                                onClick={() => debitAccount(item?.id)}
                              >
                                Debit Account now
                              </Button>
                            ) : (
                              <p
                                className={`flex gap-x-1 font-[400] text-[15px] text-[#733534]
                                        `}
                              >
                                <PiWarningCircle color="#733534" size={"5vh"} />

                                <i>Cannot debit due to low balance</i>
                              </p>
                            )} */}

                            {/* <div className="flex flex-col gap-y-1">
                              <Checkbox>
                                Check if specific amount in the balance
                              </Checkbox>
                              <Input
                                type="number"
                                placeholder="Enter specific amount"
                                className=""
                                size="large"
                                // onChange={handleAmountChange}
                              />
                              <p className="text-[13px] font-[400] text-xs">
                                This has no limit
                              </p>
                            </div> */}

                            {/* <div className={`ps-3`}>
                          <div className="mt-3">
                            <label className="">Debit other amount</label>
                            <Input
                              type="number"
                              placeholder="Enter amount"
                              className="mt-2"
                              size="large"
                              onChange={handleAmountChange}
                              status={validateMsg ? "error" : ""}
                            />

                            <p>
                              <small className="text-red-500">
                                {validateMsg}
                              </small>
                            </p>
                          </div>
                          <CustomButton
                            loading={isLoading}
                            className={`w-full mt-3`}
                            onClick={() => debitAccount(item?.id)}
                            disabled={amount <= 0}
                          >
                            Debit Account now
                          </CustomButton>
                        </div> */}
                          </div>
                          {/* <div className={`ps-3`}>
                          <div className="mt-3">
                            <label className="">Debit other amount</label>
                            <Input
                              type="number"
                              placeholder="Enter amount"
                              className="mt-2"
                              size="large"
                              onChange={handleAmountChange}
                              status={validateMsg ? "error" : ""}
                            />

                            <p>
                              <small className="text-red-500">
                                {validateMsg}
                              </small>
                            </p>
                          </div>
                          <CustomButton
                            loading={isLoading}
                            className={`w-full mt-3`}
                            onClick={() => debitAccount(item?.id)}
                            disabled={amount <= 0}
                          >
                            Debit Account now
                          </CustomButton>
                        </div> */}
                          <div className="">
                            {
                              // item?.status ? (
                              // <Button
                              //   size="middle"
                              //   loading={isLoading}
                              //   className={`rounded-[8px] bg-[#733534] text-[#FFF] hover:!bg-[#733534] hover:!text-[#FFF] capitalize`}
                              //   onClick={() => debitAccount(item?.id)}
                              // >
                              //   Debit Account now
                              // </Button>
                              // ) :
                              // (
                              //   <p
                              //     className={`flex gap-x-1 font-[400] text-[15px] text-[#733534]
                              //               `}
                              //   >
                              //     <PiWarningCircle color="#733534" size={"5vh"} />
                              //     <i>Cannot debit due to low balance</i>
                              //   </p>
                              // )
                            }

                            {/* <div className="flex flex-col gap-y-1">
                            <Checkbox>Check if specific amount in the balance</Checkbox>
                            <Input
                              type="number"
                              placeholder="Enter specifici amount"
                              className=""
                              size="large"
                              onChange={handleAmountChange}
                            />
                            <p className="text-[13px] font-[400]">This has no limit</p>

                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* <div className="mt-5 flex justify-center">
                <CustomButton className={"lg:w-[70%] md:w-[80%] w-full"} onClick={handleDebitMoney}>
                  Debit NGN 13,000
                </CustomButton>
              </div> */}
            </>
          ) : (
            <i>No accounts found</i>
          )}
        </section>
      </main>

      {contextHolder}
      {contextHolder2}
    </>
  );
}
