import { message } from "antd";

export const copyToClipboard = (text) => {
    // message.error("copy to clippoard not supported")
  if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(text)
      .then(() => {
        message.success("Link Copied to your clipboard");
        return { status: true };
      })
      .catch((error) => {
        message.error("Error copying to clipboard");
        return { status: false };
      });
  } else {
    // Fallback method
    message.error("copy to clippoard not supported")
  }
};
