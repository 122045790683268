import { formatNumberOnly } from "../../../utils/formatNumber";
import EmandateHistory from "../../../components/specific/admin_side_loan/emandateHistory/EmandateHistory";
import { useGetMandates, useStatistics } from "../../../api/getHooks";
import { useProfileData } from "../../../hooks/useProfileData";
import create_mandate_img from "../../../assets/dashboard_img_card.png";
import CustomButton from "../../../components/common/CustomButton";

import { useDrawer } from "../../../hooks/zustand_hooks/useDrawer";
import moment from "moment";
import { Select } from "../../../components/common/form_inputs/Select";
import { useMemo } from "react";

export default function EmandateDashboard({ handleClick }) {
  const { data, isLoading } = useGetMandates({
    status: [
      "inactive",
      "active",
      "part debited",
      "completed",
      "canceled",
      "confirmed",
      "reschedule pending",
      "reschedule approved",
      "reschedule declined",
    ],
  });
  const response = data?.data;

  const { data: profile } = useProfileData();

  const {data: statData} = useStatistics({
    start_date:  moment().startOf("today").format("YYYY/MM/DD"),
    end_date:  moment().endOf("today").format("YYYY/MM/DD")
  })

  const statisticData = useMemo(()=>{
    return statData?.data?.data
  }, [statData])

  const { openDrawer, updateDrawerTitle } = useDrawer();

  const totalAmount = response?.data?.reduce(
    (acc, curr) => acc + parseFloat(Number(curr.total_interest)),
    0
  );

  const totalCollection = response?.data?.reduce(
    (acc, curr) => acc + parseFloat(Number(curr.total_amount)),
    0
  );

  const createMandate = () => {
    openDrawer("MANDATE_TYPE");
    updateDrawerTitle("New Mandate");
  };

  // Set the locale to start the week on Monday
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const handleChange = (value) => {
    const startDate = moment().startOf(value).format("YYYY/MM/DD");
    const endDate = moment().endOf(value).format("YYYY/MM/DD");

    console.log(startDate, endDate);
  };


  return (
    <>
      <div className="">
        <p className="flex items-center gap-x-5 text-sm mb-3">
          Filter By:
          <Select
          size="small"
            defaultValue="all_time"
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "all_time", label: "All time" },
              { value: "today", label: "Today" },
              { value: "week", label: "This week" },
              { value: "month", label: "This month" },
              { value: "year", label: "This year" },
            ]}
          />
        </p>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mx-2">
          <div className="grid md:grid-cols-2 col-span-2 grid-cols-1 gap-5 mx-2">
            <div className="">
              <div className="h-24 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                <div className="flex justify-between">
                  <p className="font-[500] text-[14px]">
                    Mandate Request count
                  </p>
                </div>
                <p className="font-[700] text-[17px] text-[#282828]">{statisticData?.total_mandates ?? 0}</p>
              </div>
            </div>
            <div className="">
              <div className="h-24 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                <div className="flex justify-between">
                  <p className="font-[500] text-[14px]">
                    Authorized mandate count
                  </p>
                </div>
                <p className="font-[700] text-[17px] text-[#282828]">{statisticData?.total_authorized ?? 0}</p>
              </div>
            </div>
            <div className="">
              <div className="h-24 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                <div className="flex justify-between">
                  <p className="font-[500] text-[14px]">Total Value</p>
                </div>
                <p className="font-[700] text-[17px] text-[#282828]">
                  NGN {formatNumberOnly(statisticData?.total_amount ?? 0)}
                </p>
              </div>
            </div>
            <div className="">
              <div className="h-24 flex flex-col justify-between p-5 rounded bg-zinc-50 shadow">
                <div className="flex justify-between">
                  <p className="font-[500] text-[14px]">Total Collection</p>
                </div>
                <p className="font-[700] text-[17px] text-[#282828]">
                  NGN {formatNumberOnly(statisticData?.total_paid ?? 0)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={create_mandate_img}
              alt="create mandate"
              className="w-full object-cover"
            />
            <CustomButton
              className={"w-full"}
              type="submit"
              onClick={createMandate}
            >
              Create a mandate request
            </CustomButton>
          </div>
        </div>

        {/* <NewMandate handleClick={handleClick} /> */}

        <EmandateHistory handleClick={handleClick} />
      </div>
    </>
  );
}
