import { useProfileData } from "./useProfileData";
import { useStaffPosition } from "./useStaffPositions";

export const useUserPermission=()=>{
    const { data, isLoading } = useStaffPosition();

    const { data:profile } = useProfileData();


    const user_permission = data?.find((item)=>item?.id===profile?.staffTypeId)



    return { user_permission }

}