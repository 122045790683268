import React from "react";
import enlace from "../assets/enlace.png";
import grms_enlace from "../assets/grms_enlace.png";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../libs/routes";
import CustomButton from "../components/common/CustomButton";

const LandingPage = () => {

  const navigate = useNavigate()

  const signIn=()=>{
    navigate(ROUTES.staff_login);
  }
  return (
    <>
      <main className="">
        <section className="shadow-sm">
          <nav className="lg:w-[80%] md:w-[90%] w-[95%] px-3 py-4 flex items-center justify-between mx-auto">
            <div>
              <img src={enlace} alt="enlace logo" width={100} height={100} />
            </div>
            <ul>
              <li>
                <Link to={ROUTES.staff_login}>Login</Link>
              </li>
            </ul>
          </nav>
        </section>
        <section className="lg:w-[80%] md:w-[90%] w-[95%] mx-4 md:mx-auto mt-3 lg:mb-auto mb-10">
          <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-6">
            <div className="col-span-2 md:pt-10 my-auto">
              <h1 className="font-[600] md:text-[65px] text-[50px] md:leading-[80px] leading-[60px] text-[#131842]">
                Empowering Credit With Innovative Solution
              </h1>
              <h5 className="text-[#384860] text-[22px] font-[400]">
                We have built a track for your credit train to move
              </h5>
            </div>
            <div>
              <img
                src={grms_enlace}
                alt={"grms_enlace"}
                className="object-cover w-full my-auto"
              />
            </div>
          </div>

          <div className="grid grid-cols-4 mx-5 mt-3">
            <div className="md:col-span-3 col-span-4">
              <h4 className="text-xl text-[#131842] text-[18px] font-[400]">
                Supports
              </h4>
              <div className="grid md:grid-cols-3 grid-cols-1 list-disc gap-x-5 gap-y-4 mt-2">
                {[
                  "Supply and Wholesales",
                  "Rent",
                  "Leasing",
                  "Financial cooperatives",
                  "BNPL",
                  "Lending Services",
                ]?.map((item, index) => {
                  return (
                    <li
                      className="text-[#131842] text-[16px] font-[400]"
                      key={item + "___" + index}
                    >
                      {item}
                    </li>
                  );
                })}
              </div>
              <div className="mt-7 mb-4">
                <CustomButton
                onClick={signIn}
                className={"px-6"}
              >
                Sign in
              </CustomButton>

              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default LandingPage;
