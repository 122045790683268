import { useMemo, useState } from "react";
import {
  useGetMandateSchedule,
  useGetTimelinesWithID,
  useGetUserById,
} from "../../../../api/getHooks";
import { formatNumberOnly } from "../../../../utils/formatNumber";
import { IoEyeOutline } from "react-icons/io5";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import moment from "moment";
import { Table } from "../../../common/form_inputs/Table";
import { useUserPermission } from "../../../../hooks/useUserPermission";
import { formatDate } from "../../../../utils/formatDate";
import CustomButton from "../../../common/CustomButton";
import OutlineCustomButton from "../../../common/OutlineCustomButton";
import { useNavigate } from "react-router-dom";
import {
  useConfirmPayment,
  useCancelSchedule,
} from "../../../../api/admin_mutation";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { Button, Spin, ConfigProvider, Result } from "antd";
import { CiEdit } from "react-icons/ci";
import EditSchedule from "./EditSchedule";
import { exportExcel } from "../../../../utils/exportExcel";
import { FaDownload } from "react-icons/fa";
import Confirm from "../../../common/Confirm";




export default function LoanDetail() {
  const {
    openDrawer,
    updateDrawerTitle,
    pageTitle,
    data: { mandate, user },
    closeDrawer,
  } = useDrawer();

  const navigate = useNavigate();

  const { showMessage, contextHolder } = useCustomMessage();

  const { data } = useGetMandateSchedule(mandate?.id);

  const [editSchedule, setEditSchedule] = useState(false);
  const [mandateID, setMandateID] = useState();

  const { data: userData, isLoading, isError } = useGetUserById(user?.id);

  const confirmPayment = useConfirmPayment();
  const cancelSchedule = useCancelSchedule();

  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [openConfirm, setOpenConfirm] = useState(false)
  const [action, setAction] = useState("")

  const { data: getTimeline, isLoading: timelineLoading } =
    useGetTimelinesWithID(
      pageTitle === "CUSTOMER_SCHEDULE" ? user?.id : mandate?.id
    );

  const timelines = getTimeline?.data?.data;

  const {
    user_permission,
    isLoading: permissionLoading,
    isError: permissionError,
  } = useUserPermission();

  const responseData =
    pageTitle === "CUSTOMER_SCHEDULE" ? userData?.data?.data : data?.data?.data;

  // const accounts =

  const handleOpenDrawer = (detail) => {
    openDrawer("DEBIT_ACCOUNT", {
      single_schedule: detail,
      accounts: responseData?.accounts,
      
    }, pageTitle === "CUSTOMER_SCHEDULE"? "CUSTOMER_SCHEDULE": "LOAN_DETAIL");
    updateDrawerTitle(`Mandate ${moment(mandate?.createdAt).format("L")}`);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.status === "active", // Disable checkbox for rows with status "active"
    }),
    // other rowSelection properties if needed

    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Due Date",
      dataIndex: "start_date",
      render: (text) => formatDate(text),
    },
    {
      title: "Principal Payment",
      dataIndex: "amount",
      render: (text, row) => <>NGN {formatNumberOnly(text)}</>,
    },
    {
      title: "Interest Payment",
      dataIndex: "interest",
      render: (text, row) => <>NGN {formatNumberOnly(text)}</>,
    },
    {
      title: "Total amount Due",
      dataIndex: "total_amount_due",
      render: (text, row) => <>NGN {formatNumberOnly(text)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div
          className={`capitalize ma ${
            text === "not debit"
              ? "bg-red-100 text-red-900"
              : text === "part debited"
              ? "bg-yellow-100 text-yellow-900"
              : text === "fully debited"
              ? "bg-green-100 text-green-900"
              : text === "inactive"
              ? "bg-yellow-100 text-yellow-900"
              : text === "reschedule pending"
              ? "bg-purple-100 text-purple-900"
              : text === "reschedule declined"
              ? "bg-red-100 text-red-900"
              : text === "reschedule approved"
              ? "bg-green-100 text-green-900"
              : text === "active" && "bg-green-100 text-green-900"
          } px-2 rounded text-center`}
        >
          <i>{text}</i>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, row) => (
        <>
          <small
            className="cursor-pointer"
            onClick={() => handleOpenDrawer(row)}
          >
            <IoEyeOutline size={"18px"} />
          </small>
        </>
      ),
    },
  ];
  const customerMandateColumns = [
    {
      title: "Date",
      dataIndex: "start_date",
      render: (text) => formatDate(moment(text).format("L")),
    },
    {
      title: "Mandate ID",
      dataIndex: "mandateId",
    },
    {
      title: "Total amount Due",
      dataIndex: "total_amount_due",
      render: (text, row) => <>NGN {formatNumberOnly(text)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div
          className={`capitalize max-w-full ${
            text === "not debit"
              ? "bg-red-100 text-red-900"
              : text === "part debited"
              ? "bg-yellow-100 text-yellow-900"
              : text === "fully debited"
              ? "bg-green-100 text-green-900"
              : text === "inactive"
              ? "bg-yellow-100 text-yellow-900"
              : text === "reschedule pending"
              ? "bg-purple-100 text-purple-900"
              : text === "reschedule pending"
              ? "bg-purple-100 text-purple-900"
              : text === "reschedule declined"
              ? "bg-red-100 text-red-900"
              : text === "reschedule approved"
              ? "bg-green-100 text-green-900"
              : text === "active" && "bg-green-100 text-green-900"
          } px-2 rounded text-center`}
        >
          <i>{text === "active" ? "Not Due" : text}</i>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, row) => (
        <>
          <small
            className="cursor-pointer"
            onClick={() => handleOpenDrawer(row)}
          >
            <IoEyeOutline size={"18px"} />
          </small>
        </>
      ),
    },
  ];

  let dataSource = responseData?.schedules?.map((data, i) => ({
    ...data,
    key: data?.id,
  }));

  const editableDataSource = useMemo(() => {
    return dataSource;
    // ?.filter(data => data?.status==='inactive')
  }, [responseData?.schedules, selectedRowKeys]);
  // const editableDataSource = useMemo(() => {
  //   return dataSource
  //     ?.filter(data => selectedRowKeys.includes(data.id))
  // }, [responseData?.schedules, selectedRowKeys]);

  const mandateData =
    pageTitle === "CUSTOMER_SCHEDULE" ? userData?.data?.data : mandate;

  const createNewNandate = () => {

        openDrawer("MANDATE_INFO", {
          values: {
            first_name: mandateData?.first_name,
            last_name: mandateData?.last_name,
            email: mandateData?.email,
            phone: mandateData?.phone,
            bvn: mandateData?.bvn,
            address: mandateData?.address,
          },
        }, "CUSTOMER_SCHEDULE");
  };

  const handleScheduleAction = (action) => {
    setAction(action)
    setOpenConfirm(true);
   
  };

  const confirmScheduleAction =()=>{
    if (action === "cancel") {
      cancelSchedule.mutate(selectedRowKeys, {
        onError: (error) => {
          const errorMessage = error?.response?.data?.message ?? error?.message;

          showMessage("error", errorMessage);
        },
        onSuccess: (res) => {
          const val = {
            id: pageTitle === "CUSTOMER_SCHEDULE" ? user?.id : mandate?.id,
            key:
              pageTitle === "CUSTOMER_SCHEDULE"
                ? "get_user_by_id"
                : "mandate_schedule",
          };
          queryClient.invalidateQueries([val.key, val?.id]);
          setSelectedRowKeys([]);
        },
      });
    } else if (action === "confirm_payment") {
      confirmPayment.mutate(selectedRowKeys, {
        onError: (error) => {
          const errorMessage = error?.response?.data?.message ?? error?.message;

          showMessage("error", errorMessage);
        },
        onSuccess: (res) => {
          const val = {
            id: pageTitle === "CUSTOMER_SCHEDULE" ? user?.id : mandate?.id,
            key:
              pageTitle === "CUSTOMER_SCHEDULE"
                ? "get_user_by_id"
                : "mandate_schedule",
          };
          queryClient.invalidateQueries([val.key, val?.id]);

          setSelectedRowKeys([]);
        },
      });
    }
  }

  const exportAs = (value) => {
    exportExcel({
      excelData: dataSource, //responseData?.schedules,
      fileName: `${mandate?.id} schedules`,
    });
  };

  const changeEditSchedule = () => {
    if (editSchedule && mandateID === mandate?.id) {
      setMandateID(mandate?.id);
      setEditSchedule(false);
    } else {
      setMandateID(mandate?.id);
      setEditSchedule(true);
    }
  };

  const totalBalance = useMemo(() => {
    const totalSum = dataSource?.reduce((acc, curr) => acc + (parseFloat(curr?.total_amount_due) || 0), 0);
    return totalSum;
  }, [dataSource]);


  return (
    <>
      <div className="w-full max-w- mx-auto px-1 py-4 rounded-lg bg-white shadow-sm">
        <div className="flex items-center justify-between mb-3">
          <h4 className="font-[600] text-[18px] mb-2">
            Loan Repayment Schedule
          </h4>
          {pageTitle === "CUSTOMER_SCHEDULE" && (
            <CustomButton
              size="middle"
              onClick={createNewNandate}
              disabled={!user_permission?.create_mandate}
            >
              Create Mandate
            </CustomButton>
          )}
        </div>

        <div className="flex flex-col gap-y-1">
          <div className="flex justify-between">
            <p className="font-[500] text-[16px]">
              {mandateData?.first_name} {mandateData?.last_name}
            </p>
            <p className="font-[500] text-[16px]">
              Customer ID: {mandateData?.user?.customer_id}
            </p>
          </div>
          <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1 mt-2">
            <p className="font-[500] text-[14px]">
              BVN: <span className="font-[300]">{mandateData?.bvn}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Date:{" "}
              <span className="font-[300]">
                {moment(mandateData?.createdAt).format("ll")}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Phone: <span className="font-[300]">{mandateData?.phone}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Email: <span className="font-[300]">{mandateData?.email}</span>
            </p>
          </div>

          <div className="flex flex-col gap-y-1 mt-4">
            <p className="font-[500] text-[14px]">
              Loan Amount:{" "}
              <span className="font-[300]">
                NGN {formatNumberOnly(mandateData?.total_amount ?? 0)}
              </span>
            </p>
            <div className="grid grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1">
              <p className="font-[500] text-[14px]">
                Tenor:{" "}
                <span className="font-[300]">
                  {mandateData?.tenor} {mandateData?.payment_type}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-8 mb-3">
            <div className="grid md:grid-cols-3 grid-cols-2 lg:gap-x-8 gap-x-4 gap-y-1">
              <p className="font-[500] text-[14px]">
                Total Balance:{" "}
                <span className="font-[300]">NGN {(formatNumberOnly(totalBalance))}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="mb-2 flex gap-x-3">
            <div className="flex justify-between w-full">
              {selectedRowKeys?.length ? (
                <div className="flex gap-x-2">
                  <OutlineCustomButton
                    size={"middle"}
                    disabled={editSchedule && mandateID === mandate?.id}
                    loading={cancelSchedule?.isPending}
                    className={"bg-"}
                    onClick={() => handleScheduleAction("cancel")}
                  >
                    Cancel
                  </OutlineCustomButton>
                  <CustomButton
                    size={"middle"}
                    disabled={editSchedule && mandateID === mandate?.id}
                    loading={confirmPayment?.isPending}
                    onClick={() => handleScheduleAction("confirm_payment")}
                  >
                    Confirm Payment
                  </CustomButton>
                </div>
              ) : null}

              <Confirm openConfirm={openConfirm} confirmLoading={confirmPayment?.isPending || cancelSchedule?.isPending} setOpenConfirm={setOpenConfirm} message={action==='cancel'? "Are you sure to cancel this schedules": "Are you sure to confirm this schedule repayment"} handleConfirm={confirmScheduleAction} />
              <div className="flex gap-x-3">
                <Button type="text" onClick={changeEditSchedule}>
                  {editSchedule && mandateID === mandate?.id
                    ? "Restore Schedule"
                    : "Edit Schedule"}
                  <CiEdit />
                </Button>
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultHoverBorderColor: "#ef4444",
                        defaultHoverBg: "#ef4444",
                        defaultBg: "#ef4444",
                        defaultHoverColor: "#fff",
                        defaultColor: "#fff",
                        defaultBorderColor: "#ef4444",
                        defaultActiveBorderColor: "#ef4444",
                        defaultActiveBg: "#ef4444",
                        defaultActiveColor: "#FFF",
                        defaultShadow: "0",
                      },
                    },
                  }}
                >
                  <Button onClick={exportAs}>
                    Export Schedules
                    <FaDownload />
                  </Button>
                </ConfigProvider>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : isError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : permissionLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : permissionError ? (
            <Result
              status="error"
              title="Unexpected error occurred"
              extra={null}
            />
          ) : user_permission?.view_schedules ? (
            <>
              {editSchedule && mandateID === mandate?.id ? (
                <>
                  <EditSchedule
                    schedules={editableDataSource}
                    setEditSchedule={setEditSchedule}
                    mandateID={mandate?.id}
                    mandate={mandate}
                  />
                </>
              ) : (
                <Table
                  header_bg={true}
                  pagination={false}
                  onRow={(row) => {
                    return {
                      onClick: () => handleOpenDrawer(row), // Add click event
                    };
                  }}
                  rowSelection={rowSelection}
                  columns={
                    pageTitle === "CUSTOMER_SCHEDULE"
                      ? customerMandateColumns
                      : columns
                  }
                  dataSource={dataSource}
                  size="small"
                  className={"w-full"}
                  rowClassName="cursor-pointer hover:bg-gray-100"
                />
              )}
            </>
          ) : (
            <div className="text-center my-10">
              <i className="text-lg">You are not permitted to view schedules</i>
            </div>
          )}
        </div>
      </div>
      {contextHolder}
    </>
  );
}
