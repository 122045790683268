import React from "react";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { useGetTimelines } from "../../../../api/getHooks";
import moment from "moment";
import TimelineCard from "../../../common/timeline/TimelineCard";
import { groupByDateArray } from "../../../../utils/groupDataByDate";
import { Pagination, Spin, Result } from 'antd';

export const TimelineView = () => {
  //external hooks

  const { data: getTimeline, isLoading, isError } = useGetTimelines(1); //it will fetch the first page on initial

  //

  const timelines = getTimeline?.data?.data;

  const formattedTimelines = timelines?.length? groupByDateArray(timelines) : [];

  console.log(formattedTimelines);

  return (
    <>
      <main>
        <div className="flex justify-between mx-4">
          <p className="text-[18px] font-[500]">Timeline</p>
          <div className="flex gap-x-3 items-center">
            <p className="text-[14px] font-[300]">Activities</p>
            <p className="flex justify-center items-center h-7 w-7 py-[10px] px-[10px] bg-[#FDF4F4] text-[#733534] border border-[#F9DADA] rounded-[5px]">
              4
            </p>
          </div>
        </div>

        <div className="w-full max-w-3xl mx-auto mt-4 mb-3">
          {
            isLoading ? (
              <div className="flex justify-center">
                <Spin />
              </div>
            ) : isError ? (
              <Result
                status="error"
                title="Unexpected error occurred"
                extra={null}
              />
            ) :
            formattedTimelines.map((item, index)=>{
              return(
                  <TimelineCard index={index} key={index+"__parent"} timeline={item}/>
              )
            })
          }
          {/* <Pagination defaultCurrent={1} total={100} /> */}
        </div>
      </main>
    </>
  );
};
