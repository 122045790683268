import { useQuery } from "@tanstack/react-query"
import { getBankList, getCustomer, getMandates, getMandateSchedule, getPositions, getProfile, getStaff, getUserMandate, getUserByID, getTimelines, getTimelinesWithID, viewSchedule, getTransaction, getStatatic } from "./fetchingFn"

export const useGetProfile=()=>{
    return useQuery({
        queryKey: ["get_profile"], queryFn: getProfile
    })
}

export const useGetPositions=()=>{


    return useQuery({
        queryKey: ["positions"], queryFn: getPositions
    })
}
export const useGetTimelines=(page)=>{
    return useQuery({
        queryKey: ["timelines", page], queryFn: getTimelines
    })
}
export const useGetTimelinesWithID=(ID)=>{
    return useQuery({
        queryKey: ["timelines_with_id", ID], queryFn: getTimelinesWithID
    })
}

export const useGetMandates=(payload)=>{
    return useQuery({
        queryKey: ["all_mandates", payload],
        queryFn:()=>getMandates(payload)
    })
}


export const useGetMandateSchedule=(mandateID)=>{
    return useQuery({
        queryKey: ["mandate_schedule", mandateID], queryFn: getMandateSchedule
    })
}
export const useGetUserById=(userID)=>{
    return useQuery({
        queryKey: ["get_user_by_id", userID], queryFn: getUserByID
    })
}


export const useGetUserMandate=(orderID)=>{
    return useQuery({
        queryKey: ["user_mandate", orderID], queryFn: getUserMandate
    })
}

export const useGetCustomers=(page)=>{
    return useQuery({
        queryKey: ['customers', page],
        queryFn: getCustomer
    })
}

export const useGetStaff=()=>{
    return useQuery({
        queryKey: ['staffs'],
        queryFn: getStaff
    })
}

export const useGetBankList=()=>{
    return useQuery({
        queryKey: ['bank_list'],
        queryFn: getBankList
    })
}
export const useViewSchedule=(scheduleID)=>{
    return useQuery({
        queryKey: ['view_schedule', scheduleID],
        queryFn: viewSchedule
    })
}
export const useTransactions=(payload)=>{
    return useQuery({
        queryKey: ['get_transaction', payload],
        queryFn:getTransaction
    })
}
export const useStatistics=(payload)=>{
    return useQuery({
        queryKey: ['get_statistic', payload],
        queryFn:getStatatic
    })
}
