

import { Drawer } from "antd";





export default function DrawerBox({ open, handleClose, children, anchor, size, title }) {


  return (
    <>


      <Drawer open={open} onClose={handleClose} width={size} title={title}>
        <section className="px-lg-3">{children}</section>
      </Drawer>
    </>
  );
}
