import React from 'react'
import { Checkbox as AntCheckbox, ConfigProvider } from "antd"

export const Checkbox = ({className, ...rest}) => {
  return (
    <>
    <ConfigProvider theme={{
            components: {
                Checkbox: {
                    colorPrimary: "#733534",
                    colorPrimaryHover: "#733534",
                    colorPrimaryBorder: "#733534",
                    activeShadow: "0 0 0 2px #733534",
                }
            }
        }}>

         <AntCheckbox
         
               {...rest}
                className={`${className}`}
              />

        </ConfigProvider>
    </>
  )
}
