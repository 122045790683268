


import React, { useState } from 'react';
import { Tag } from 'antd';
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";
import { formatNumberOnly } from '../../../../utils/formatNumber';
import { Table } from '../../../common/form_inputs/Table';



const EmandateHistoryTable = ({rows, handleOpen}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;


  const dataSource = rows?.map((data, i) => ({...data, key: data?.id+i}));







  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, row) => (
        <div className="flex flex-col">
          <p className="font-bold my-auto">{`${row?.first_name} ${row?.last_name}`}</p>
          <small className="text-gray-400" style={{ fontSize: '12px' }}>
            {moment(row?.createdAt).format('h:mm:ss, D MMMM YYYY')}
          </small>
        </div>
      ),
      
    },
    {
      title: 'Mandate Ref',
      dataIndex: 'reference',
      render: (text, row)=>(
        <>
          {row?.order_id}
        </>
      )
    },
    {
      title: 'Total Repayable amount',
      dataIndex: 'totalRepayment',
      render: (text, row)=>(
        <>
          NGN {formatNumberOnly(parseFloat(row?.total_amount) - parseFloat(row?.total_paid))}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text)=>(
        <Tag color={text==='inactive'?"orange": text==='reschedule pending'? 'purple' : 'success'} className='px-3 py-1 capitalize border-0'>{text}</Tag>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, row)=>(
        <>
          <small className='cursor-pointer' onClick={()=>handleOpen(row)}>
            <IoEyeOutline size={"18px"}/>
  
          </small>
        </>
      )
    },
  ];





  return (
    <>
        <Table rowSelection={rowSelection} columns={columns} dataSource={dataSource} />
    
    </>
  );
};
export default EmandateHistoryTable;